
import { defineStore } from 'pinia';

export const useProductStore = defineStore('products', {
  state: () => ({
    products: [],
  }),

  getters: {
    totalProducts: (state) => state.products.length,
  },

  actions: {
    addProduct(product) {
      this.products.push(product);
    },
    removeProductById(productId) {
      this.products = this.products.filter(product => product.id !== productId);
    },
    removeProductByIndex(index) {
      this.products.splice(index, 1);
    },
    clearProducts() {
      this.products = [];
    },
  },

  persist: true, 
});