<template>

  <div class="scanner" v-show="isScanning">
    <div id="video" autoplay></div>
    <!-- canvas id="canvas" class="overlay"></canvas -->
    <span>{{ $t('zoom_in_on_the_barcode') }}</span>
  </div>
  <div v-if="camError"> {{ $t('error_cam') }}</div>
  <div v-if="saved" class="saved">{{ $t('product_added') }} <router-link to="/list"><i
        class="fas fa-shopping-cart"></i></router-link></div>
  <div v-if="decodedCode" :class="result">
    <span class="decoded">{{ decodedCode }}</span>
    <div v-if="loading">{{ $t('waiting') }}</div>
    <div v-if="product && product.price" class="card">
      <div class="card-header">
        <h2 class="price">{{ product.source }} {{ product.currency }} {{ product.price }}</h2>
        <p class="quantity">{{ product.quantity }}</p>
      </div>
      <div class="card-price-details" v-for="priceDetail in filteredPriceDetails" :key="priceDetail.source">
        <span>{{ priceDetail.source }}: </span>
        <span>{{ product.currency }} {{ priceDetail.price }}</span>
      </div>
      <div class="card-body">
        <p class="product-name">{{ product.name }}</p>
        <span class="brand">{{ product.brand }}</span>
      </div>
      <div class="card-footer">
        <button @click="save()" class="save-button"> <i class="fas fa-cart-plus"></i> {{ $t('save_title') }}</button>
      </div>
    </div>

    <div v-if="notfound">
      <img src="/assets/notfound.svg" style="max-width: 100px; max-height: 100px;">
      <br />{{ $t('no_matches_found') }}
      <br />{{ $t('review_configs') }} <router-link to="/config"><i class="fas fa-cog"></i></router-link>
    </div>
  </div>
  <div class="controls">

    <button v-if="isScanning" class="stop-button" @click="stopScanning"><i class="fas fa-barcode"></i> {{
      $t('stop_scanner') }}</button>
    <button v-else class="start-button" @click="startScanning"><i class="fas fa-barcode"></i> {{ $t('start_scanner')
      }}</button>

  </div>

  <LaterCode ref="laterCodeComponent" />

</template>

<script>
import Quagga from 'quagga';
import axios from 'axios';
import LaterCode from '@/components/LaterCode.vue';
import { useProductStore } from '@/stores/productStore';

export default {
  name: 'ScannerView',
  components: {
    LaterCode // Register the component
  },
  data() {
    return {
      isScanning: false,
      decodedCode: null,
      decodedCodes: [],
      product: null,
      loading: false,
      notfound: false,
      camError: false,
      saved: false,
    };
  },
  methods: {
    save() {
      const store = useProductStore();
      const exists = store.products.some(p => p.gtin === this.product.gtin);
      if (!exists) {
        const now = new Date();
        const formattedDate = `${now.getFullYear()}/${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
        store.addProduct({ ...this.product, date: formattedDate });
      }
      this.product = null;
      this.decodedCode = null;

      setTimeout(() => {
        this.saved = true;
      }, 250);

      setTimeout(() => {
        this.saved = false;
        //this.startScanning();
      }, 2750); // 3000 milliseconds = 3 seconds
    },
    playBeep(frequency, duration, startTime, context) {
      const oscillator = context.createOscillator();
      const gainNode = context.createGain();

      oscillator.type = 'square';
      oscillator.frequency.setValueAtTime(frequency, context.currentTime + startTime);
      oscillator.connect(gainNode);
      gainNode.connect(context.destination);

      oscillator.start(context.currentTime + startTime);
      gainNode.gain.exponentialRampToValueAtTime(0.00001, context.currentTime + startTime + duration);
      oscillator.stop(context.currentTime + startTime + duration);
    },
    playScanSound() {
      const context = new (window.AudioContext || window.webkitAudioContext)();
      this.playBeep(1500, 0.1, 0, context);    // First beep: 1500 Hz, 0.1 second
      this.playBeep(1000, 0.1, 0.1, context);  // Second beep: 1000 Hz, 0.1 second, starting after the first beep
    },
    formatEAN13(ean) {
      // Check if the input is a string and has exactly 13 digits
      if (typeof ean !== 'string' || ean.length !== 13 || !/^\d{13}$/.test(ean)) {
        throw new Error('Invalid EAN-13 number');
      }
      // Extract the parts based on the required format
      const part1 = ean.slice(0, 1);     // First digit
      const part2 = ean.slice(1, 7);     // Next six digits
      const part3 = ean.slice(7, 13);    // Last six digits
      // Concatenate the parts with spaces in between
      return `${part1} ${part2} ${part3}`;
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    async getCameraID() {
      let defaultDeviceId = null;
      try {
        // Request permission to access media devices
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // Enumerate devices after permission is granted
        const devices = await navigator.mediaDevices.enumerateDevices();

        let i = 0;
        let k = 2;

        if (!this.isIOS()) {
          k = 100;
        }

        devices.forEach(device => {
          if (device.kind === 'videoinput') {
            i = i + 1;
            if (i <= k) {
              defaultDeviceId = device.deviceId;
            }
          }
        });

        // Stop the video stream after enumeration
        stream.getTracks().forEach(track => track.stop());
        return defaultDeviceId;
      } catch (error) {
        console.error('Error listing devices:', error);
        return defaultDeviceId;
      }
    },
    async getCameraDecideID() {
      const telescopeKeywords = ['telephoto', 'teleobjetiva', 'téléphoto', 'teleobjetivo', 'longue', 'telefotokamera', 'телефотокамера', 'telelens', 'teleobiettivo'];
      let defaultDeviceId = null;
      try {
        // Request permission to access media devices
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // Enumerate devices after permission is granted
        const devices = await navigator.mediaDevices.enumerateDevices();
        let telephotoDeviceId = defaultDeviceId;

        devices.forEach(device => {
          if (device.kind === 'videoinput') {
            // Check if the device label includes any of the telescope keywords
            if (telescopeKeywords.some(keyword => String(device.label).toLowerCase().includes(String(keyword).toLowerCase()))) {
              telephotoDeviceId = device.deviceId;
            }
            defaultDeviceId = device.deviceId;
          }
        });

        // Stop the video stream after enumeration
        stream.getTracks().forEach(track => track.stop());

        if (telephotoDeviceId === null) {
          return defaultDeviceId;
        }

        return telephotoDeviceId;
      } catch (error) {
        console.error('Error listing devices:', error);
        return defaultDeviceId;
      }
    },
    async startScanning() {
      this.isScanning = true;
      const camId = await this.getCameraID();
      this.initializeQuagga(camId);
      Quagga.onDetected(this.onDetected);

      Quagga.onProcessed(function (result) {
        var drawingCtx = Quagga.canvas.ctx.overlay,
          drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
          if (result.boxes) {
            drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));

          }

          if (result.box) {
            Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 4 });
          }

          if (result.codeResult && result.codeResult.code) {
            // Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
          }
        } else {
          drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        }
      });
    },
    initializeQuagga(camId) {
      const isPortrait = window.innerHeight > window.innerWidth;
      const iWigth = screen.width * 2;
      const iHeight = Math.round(2 * (screen.height / 5));

      const xwidth = isPortrait ? iHeight : iWigth;
      const yheight = isPortrait ? iWigth : iHeight;

      Quagga.init(
        {
          multiple: false,
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: document.querySelector('#video'),
            constraints: {
              width: xwidth,
              height: yheight,
              aspectRatio: { ideal: xwidth / yheight },
              facingMode: 'environment',
              deviceId: camId,
              frameRate: { ideal: 30 },
              advanced: [
                { whiteBalanceMode: "continuous" },  // Continuous white balance adjustment
                { whiteBalanceTemperature: 3200 },   // Set white balance temperature to 5000K
                { focusMode: "continuous" },         // Continuous auto-focus
                { focusDistance: 0 },                // Set focus distance (0 for macro focus)
                { exposureMode: "continuous" },      // Continuous exposure adjustment
                { exposureCompensation: 1 },         // Exposure compensation (-10 to +10 typically)
                { brightness: 0.5 },                   // Set brightness (typically -1 to +1)
                { contrast: 0.2 },                     // Set contrast (typically -1 to +1)
                { saturation: 0.2 },                   // Set saturation (typically -1 to +1)
                { sharpness: 0.2 },                    // Set sharpness (typically -1 to +1)
                { zoom: 1 },                         // Set zoom level (1 for no zoom, higher for zoom)
              ],
            },
            area: {
              top: "0.1%",
              right: "5%",
              left: "5%",
              bottom: "0.1%"
            }
          },
          numOfWorkers: 6,
          frequency: 40, // 20 images per sec
          debug: false,
          decoder: {
            readers: ['ean_reader']
          },
          lastResult: null,
          locator: {
            patchSize: "large", // Options: "x-small", "small", "medium", "large", "x-large"
            halfSample: false, // Whether to reduce the resolution by half
            // Show the locator
            drawBoundingBox: true, // Draw the bounding box around the located barcode
            drawScanline: false, // Draw a scanline where the barcode is located
          },
        },
        (err) => {
          if (err) {
            console.log(err);

            if (String(err.message).toLowerCase().includes('permission') && String(err.message).toLowerCase().includes('denied')) {
              //this.decodedCode = 'Error: Permission denied!';
              this.camError = true;
            } else {
              this.decodedCode = err;
            }

            return;
          }
          Quagga.start();
        }
      );
    },
    getMostFrequentCode(codes) {
      const frequencyMap = codes.reduce((acc, code) => {
        acc[code] = (acc[code] || 0) + 1;
        return acc;
      }, {});

      let mostFrequentCode = null;
      let maxCount = 0;
      const totalCount = codes.length;

      for (const [code, count] of Object.entries(frequencyMap)) {
        if (count > maxCount) {
          mostFrequentCode = code;
          maxCount = count;
        }
      }
      // Check if the most frequent code appears more than 50% of the time
      if (maxCount > totalCount / 1.3) {
        return mostFrequentCode;
      } else {
        return null; // Return null if no code has more than 50% presence
      }
    },
    checkDecodedCodes(decodedCodes) {
      // Check if every element in the array meets the criteria
      return decodedCodes.every(function (element) {
        if (element.code !== -1) {
          return element.error < 0.1001;
        }
        return true; // If code is -1, the condition is automatically met
      });
    },
    calculateErrorAverage(decodedCodes) {
      // Filter out elements where code == -1
      const validElements = decodedCodes.filter(element => element.code !== -1);

      // If there are no valid elements, return 0 to avoid division by zero
      if (validElements.length === 0) {
        return 0;
      }

      // Calculate the sum of the error values of the valid elements
      const errorSum = validElements.reduce((sum, element) => sum + element.error, 0);

      // Calculate the average by dividing the sum by the number of valid elements
      const averageError = errorSum / validElements.length;

      return averageError;
    },
    stopScanning() {
      this.isScanning = false;
      this.camError = false;

      if (this.notfound) {
        this.decodedCode = '';
      }
      this.notfound = false;
      Quagga.stop();

      if (String(this.decodedCode).toLowerCase().includes('error')) {
        this.decodedCode = '';
      }

    },
    beforeUnmount() {
      this.stopScanning();
    },
    onDetected(result) {

      // add 8 elements
      if (this.decodedCodes.length < 7) {
        this.decodedCodes.push(result.codeResult.code); // Add the new result
        return false;
      }

      this.decodedCodes.push(result.codeResult.code);

      const mostFrequentCode = this.getMostFrequentCode(this.decodedCodes);
      // remove 1 element from the begining 
      this.decodedCodes.shift();

      if (mostFrequentCode === null) {
        return;
      }

      if (this.formatEAN13(mostFrequentCode) != this.decodedCode) {
        this.decodedCode = this.formatEAN13(result.codeResult.code);
        this.playScanSound();
        this.decodeNow(result.codeResult.code);
      }

      //this.stopScanning();

    },
    async buildFilter(country) {
      let storedStoresData = localStorage.getItem(`stores_${country}`);

      if (storedStoresData === null) {
        try {
          const response = await axios.get(`https://paodu.ro/${country}/stores`);
          this.stores = response.data.map(store => ({
            ...store,
          }));
          localStorage.setItem(`stores_${country}`, JSON.stringify(this.stores));
          localStorage.setItem(`stores_timestamp_${country}`, new Date().getTime().toString());
          storedStoresData = localStorage.getItem(`stores_${country}`);
        } catch (error) {
          console.error('Error fetching stores:', error);
        }
      }

      if (storedStoresData) {
        const stores = JSON.parse(storedStoresData).sort((a, b) => a.id - b.id); // Ensure stores are sorted by id
        const filter = stores.map(item => {
          if (item.enabled) {
            if (item.compute && item.nearest !== null) {
              return item.nearest;
            }
            return '1';
          } else {
            return '0';
          }
        }).join(',');

        return '?filter=' + filter;
      }

      return '';
    },
    saveCodeForLater(code) {
      // Only save the code if it doesn't start with 26 or 28
      if (code.startsWith('26') || code.startsWith('28')) {
        return;
      }

      let codes = JSON.parse(localStorage.getItem('later')) || [];

      if (codes.length >= 50) {
        codes.pop(); // Remove the oldest code (last one) if we have 50 stored
      }
      codes.unshift(code); // Add the new code to the beginning of the array
      localStorage.setItem('later', JSON.stringify(codes));
      this.$refs.laterCodeComponent.loadSavedCodes();
    },
    async decodeNow(code) {
      this.product = null;
      this.loading = true;
      this.notfound = false;
      try {
        const country = localStorage.getItem('country');
        // build the filter and fetch the stores if needed
        const controller = new AbortController();

        const timeoutId = setTimeout(() => {
          controller.abort();
        }, 4000); // Set a timeout of 4 seconds

        const filter = await this.buildFilter(country);
        const userGUID = localStorage.getItem('user-guid');
        const response = await fetch(`https://paodu.ro/${country}/price/${code}${filter}`, {
          signal: controller.signal,
          headers: { 'X-User-GUID': userGUID },
        });

        clearTimeout(timeoutId); // Clear the timeout if the request completes in time

        if (!response.ok) {
          throw new Error(this.$t('network_error') + ' ' + response.statusText);
          /**  throw new Error('Network response was not ok ' + response.statusText);*/
        }

        this.product = await response.json();

        if (!this.product || this.product.price === undefined || this.product.price === null || this.product.price === '') {
          this.notfound = true;
          this.product = null;
        }

      } catch (error) {
        console.log(error.message);
        this.saveCodeForLater(code);
        this.notfound = true;
        this.product = null;
      } finally {
        console.log('done');
        this.loading = false;
      }
    },
  },
  watch() {

  },
  mounted() {
    this.startScanning();
  },
  unmounted() {
    this.stopScanning();
    Quagga.offDetected(this.onDetected);
  },
  computed: {
    filteredPriceDetails() {
      return this.product.list.filter(priceDetail => priceDetail.source !== this.product.source);
    }
  }
};
</script>

<style>
#app {
  text-align: center;
}

.saved {
  background-color: #3498db;
  /* Blue background */
  border: 1px solid #3498db;
  /* Blue border */
  border-radius: 5px;
  /* Rounded corners */
  padding: 10px 15px;
  /* Padding inside the box */
  color: white;
  /* White text color */
  display: inline-block;
  /* Keep it inline with padding and margins */
  margin-top: 10px;
  /* Spacing from other elements */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  font-size: 16px;
  /* Font size */
}

/* Ensure the link inside .saved doesn't inherit unwanted styles */
.saved a {
  color: white;
  /* White color for the link */
  text-decoration: none;
  /* Remove underline */
  margin-left: 5px;
  /* Space between text and icon */
}

/* Optional: Add hover effect to the link */
.saved a:hover {
  color: #ecf0f1;
  /* Slightly lighter white/gray on hover */
}

.scanner {
  position: relative;
  width: 100%;
  left: 0px;
  top: 0px;
  height: auto;
  /* 25% of the screen */
  /* overflow: hidden; */
}

video {
  /* width: 640px;  */
  /* height: 180px; */
  width: 100%;
  left: 0px;
  top: 0px;
  height: auto;
  object-fit: contain;
  /*object-fit: cover;*/
}

.overlay,
.drawingBuffer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  /* Ensure it's on top */
  pointer-events: none;
  /* Prevent it from blocking interactions */
}

/** -- */

.controls {
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  /* Arrange buttons in a column */
  align-items: center;
  /* Center buttons horizontally */
}

.controls .start-button,
.controls .stop-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  position: fixed;
  bottom: 60px;
  /* Adjust this value if you want more space between the buttons */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  /* Ensure they stay on top of other elements */
}

.controls .start-button {
  background-color: #28a745;
  /* Green color for start */
}

.controls .start-button:hover {
  background-color: #218838;
  /* Darker green on hover */
}

.controls .stop-button {
  background-color: #dc3545;
  /* Red color for stop */
  bottom: 10px;
  /* Position it below the start button */
}

.controls .stop-button:hover {
  background-color: #c82333;
  /* Darker red on hover */
}

.controls .start-button:active,
.controls .stop-button:active {
  transform: translateX(-50%) scale(0.98);
}

.result {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  text-align: center;
  font-size: 18px;
}

.decoded {
  font-weight: bold;
  color: #333;
}

.product {
  width: 84vw;
  border-radius: 9px;
  margin-left: 20px;
  background-color: #f8f8f8;
  margin-top: 20px;

  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.save-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #6c757d;
  /* Blue color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 0 auto;
  /* Center the button horizontally */
  display: block;
  /* Ensure the button takes up space like a block element */
}

.save-button:hover {
  background-color: #5a6268;
  /* Darker blue on hover */
}

.save-button:active {
  transform: scale(0.98);
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  /* Center the card horizontally */
}

.card-header {
  background-color: #3498db;
  /* #3498db; */
  color: white;
  padding: 16px;
  text-align: center;
}

.product-name {
  margin: 0;
  font-size: 18px;
}

.brand {
  font-size: 14px;
  font-weight: 300;
  color: #777;
}

.card-price-details {
  background-color: #ecf0f1;
  padding: 3px;
  text-align: center;
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
}

.card-body {
  padding: 16px;
  text-align: center;
  color: #777;
}

.card-footer {
  padding-bottom: 18px;
  text-align: center;
}

.price {
  font-size: 20px;
  margin: 0 0 10px 0;
}

.quantity {
  font-size: 16px;
  margin: 0;
}

.source {
  margin: 0;
  font-size: 14px;
  color: #414141;
  margin-bottom: 10px;
}
</style>
