<template>
    <div>
      <h1>Total Price and Product Count per Source (All Products Available)</h1>
      <div v-if="sortedFilteredSources.length > 0">
        <div v-for="source in sortedFilteredSources" :key="source.source">
          <p>
            <strong>{{ source.source }}:</strong> €{{ source.totalPrice.toFixed(2) }} ({{ source.productCount }} products)
          </p>
        </div>
      </div>
      <div v-else>
        <p>Can’t use only one store to purchase all the products.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        products: [],
        sourceInfo: {},
        filteredSources: []
      };
    },
    mounted() {
      this.calculateSourceInfo();
    },
    computed: {
      sortedFilteredSources() {
        // Return a sorted copy of the filteredSources array
        return [...this.filteredSources].sort((a, b) => b.totalPrice - a.totalPrice);
      }
    },
    methods: {
      loadItemsFromLocalStorage() {
            const storedProducts = localStorage.getItem('products');
            if (storedProducts) {
                this.products = JSON.parse(storedProducts).products.map(product => ({
                    ...product,
                    swipeOffset: 0, // Initialize swipeOffset for each product
                    count: 1, // Initialize count
                    price: parseFloat(product.price), // Ensure price is a number
                }));
            }
      },
      calculateSourceInfo() {
        const sourceInfo = {};
        const productCount = this.products.length;
  
        // Find sources and accumulate price and product count
        this.products.forEach(product => {
          product.list.forEach(item => {
            if (!sourceInfo[item.source]) {
              sourceInfo[item.source] = {
                totalPrice: 0,
                productCount: 0,
                productMatchCount: 0 // To track how many products this source has
              };
            }
            // Add price and increment product match count
            sourceInfo[item.source].totalPrice += parseFloat(item.price);
            sourceInfo[item.source].productCount += 1;
          });
        });
  
        // Count product matches per source
        this.products.forEach(product => {
          const sourcesInProduct = product.list.map(item => item.source);
          Object.keys(sourceInfo).forEach(source => {
            if (sourcesInProduct.includes(source)) {
              sourceInfo[source].productMatchCount += 1;
            }
          });
        });
  
        // Filter sources that have all products
        this.filteredSources = Object.keys(sourceInfo)
          .filter(source => sourceInfo[source].productMatchCount === productCount)
          .map(source => ({
            source,
            totalPrice: sourceInfo[source].totalPrice,
            productCount: sourceInfo[source].productCount
          }));
  
        this.sourceInfo = sourceInfo;
      }
    }
  };
  </script>  