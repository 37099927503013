<!-- src/components/ToggleSwitch.vue -->
<template>
    <div class="toggle-switch">
      <input
        type="checkbox"
        :checked="modelValue"
        @change="updateValue"
      />
      <span class="slider"></span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ToggleSwitch',
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
    },
    methods: {
      updateValue(event) {
        this.$emit('update:modelValue', event.target.checked);
      },
    },
  };
  </script>
  
  <style scoped>

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 18px;
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.2s;
    border-radius: 16px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #59d28b;
  }
  
  input:checked + .slider:before {
    transform: translateX(18px);
  }
  </style>
  