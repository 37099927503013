<template>
  <div></div>
</template>
<script>
import { onMounted } from 'vue';

export default {
  name: 'CountryFetcher',
  setup() {
    const getCountry = async () => {
      const storedCountry = localStorage.getItem('country');
      
      if (storedCountry) {
        const country = storedCountry.toLowerCase();
        if (country.length === 2 && /^[a-z]{2}$/.test(country)) {
          return country;
        }
        return 'nd';
      }

      const apiUrl = 'https://ipapi.co/json/';
      
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        const country = data.country.toLowerCase();

        if (country.length === 2 && /^[a-z]{2}$/.test(country)) {
          localStorage.setItem('country', country);
          return country;
        } else {
          console.error(`Country code from API is invalid: ${country}`);
          return 'nd';
        }
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        return 'nd';
      }
    };

    onMounted(() => {
      getCountry(); 
    });

    return {
      getCountry,
    };
  },
};
</script>