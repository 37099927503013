<!-- src/components/StoreList.vue -->
<template>
    <div>
      <h3>{{ $t('stores') }} :</h3>
      <div v-if="stores.length">
        <div v-for="store in stores" :key="store.id" :class="label-container">
          <label>
            <ToggleSwitch v-model="store.enabled" @change="onToggleChange(store)" /> 
            {{ store.name }} 
          </label>
          <span v-if="store.compute && store.address && store.address!= 'loading'" style="margin-left: 5px; font-size: 12px;" class="info"> (<a :href="`https://www.google.com/maps/dir/?api=1&destination=${store.lat},${store.long}`" target="_blank">{{ store.address }})</a></span>
          <span v-else-if="store.compute && store.address && store.address== 'loading'"  style="margin-left: 5px; font-size: 12px;" class="info"> *{{ $t('waiting') }}</span>
          <span v-else-if="store.compute" class="warning"> *{{ $t('location_required') }}</span>
          <span v-else-if="!store.compute && store.address!= 'loading' && store.address!= ''" class="address"> ({{store.address}})</span>
        </div>
      </div>
      <div v-else>
        {{ $t('stores_not_found') }} 
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import ToggleSwitch from './ToggleSwitch.vue';
  
  export default {
    components: {
      ToggleSwitch,
    },
    data() {
      return {
        stores: [],
      };
    },
    created() {
      this.fetchCountryAndStores();
    },
    methods: {
      async fetchCountryAndStores() {
        this.fetchStores();
      },
      async fetchStores() {
        const country = localStorage.getItem('country');
        
        const storedStoresData = localStorage.getItem(`stores_${country}`);
        const storedTimestamp = localStorage.getItem(`stores_timestamp_${country}`);
        
        if (storedStoresData && storedTimestamp) {
          const now = new Date().getTime();
          const timestamp = parseInt(storedTimestamp, 10);
          const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000;

          if (now - timestamp < twoDaysInMillis) {
            this.stores = JSON.parse(storedStoresData);
            return;
          }
        }

        try {
          const response = await axios.get(`https://paodu.ro/${country}/stores`);
          this.stores = response.data.map(store => ({
            ...store,
          }));
          localStorage.setItem(`stores_${country}`, JSON.stringify(this.stores));
          localStorage.setItem(`stores_timestamp_${country}`, new Date().getTime().toString());
        } catch (error) {
          console.error('Error fetching stores:', error);
        }
      },
      saveStoresToLocalStorage() {
        const country = localStorage.getItem('country');
        if (country) {
          localStorage.setItem(`stores_${country}`, JSON.stringify(this.stores));
          localStorage.setItem(`stores_timestamp_${country}`, new Date().getTime().toString());
        }
      },
      haversine(lat1, lon1, lat2, lon2) {
        const toRadians = degree => degree * (Math.PI / 180);
        const R = 6371; // Radius of the Earth in kilometers
  
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                  Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
        return R * c; // Distance in kilometers
      },
      calculateNearestID(myLat, myLon, data) {
        let nearestID = null;
        let minDistance = Infinity;
        data.forEach(entry => {
          const [id, lat, lon] = entry.map(Number);
          const distance = this.haversine(myLat, myLon, lat, lon);
          if (distance < minDistance) {
            minDistance = distance;
            nearestID = id;
          }
        });
        return nearestID;
      },
      getCurrentPosition() {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
      },
      async onToggleChange(store) {
        if (store.compute){
          if (store.enabled) {
              store.address = 'loading';
            try {
              const country = localStorage.getItem('country');
              const response = await fetch(`https://paodu.ro/${country}/stores/location/${store.id}`);
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);  
              }
              const data = await response.json();
              const position = await this.getCurrentPosition();
              store.nearest = this.calculateNearestID(position.coords.latitude,position.coords.longitude, data);
              this.getStoreAdress(store);
            } catch (error) {
                console.log(error.message);
                store.enabled = false;
                store.address = '';
            }
          } else {
            store.nearest = null;
            store.address = '';
          }
        }
      },
      isNumeric(value) {
          return typeof value === 'number' && !isNaN(value);
      },
      async getStoreAdress(store) {
         if (this.isNumeric(store.nearest)  && store.compute) {
          try {
            const country = localStorage.getItem('country');
            const response = await fetch(`https://paodu.ro/${country}/store/address/${store.id}/${store.nearest}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            store.address = data.address;
            store.lat = data.lat;
            store.long = data.long; 
            return data.address;
          } catch  (error) {
            console.log(error.message);
          }
         }
      },
    },
    watch: {
        stores: {
          handler() {
            this.saveStoresToLocalStorage();
          },
          deep: true,
        },
      },
  };
  </script>
  
<style scoped>
.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label-container label {
  display: flex;
  align-items: center;
  margin: 8px 0 8px 0;
}

.label-container span {
  margin-left: 10px;
}

.warning {
  color: crimson;
  font-size: 12px;
}

.info a,.info a:visited  {
  color:cadetblue;
  font-size: 12px;
}

.address {
  color:#606060;
  font-size: 12px;
}

</style>
  