import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ScanView from '../views/ScanView.vue'
import ScannerView from '../views/ScannerView.vue'
import SettingsView from '../views/SettingsView.vue'
import CartView from '../views/CartView.vue'
import ShoppingCart from '../views/ShoppingCart.vue'
import TermsConditions from '../views/TermsConditions.vue'
import TestView from '../views/TestView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/scan',
    name: 'scan',
    component: ScanView
  },
  {
    path: '/scanner',
    name: 'scanner',
    component: ScannerView
  },
  {
    path: '/config',
    name:  'config',
    component: SettingsView
  },
  {
    path: '/cart',
    name:  'cart',
    component: CartView
  },
  {
    path: '/list',
    name:  'list',
    component: ShoppingCart
  },
  {
    path: '/terms',
    name:  'terms',
    component: TermsConditions
  },
  {
    path: '/test',
    name:  'test',
    component: TestView
  },        
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
