import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import router from './router';
import messages from './messages';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const defaultLocale = 'en';
let browserLocale = navigator.language.split('-')[0];
if (!Object.keys(messages).includes(browserLocale)) {
    browserLocale = defaultLocale;
}

const i18n = createI18n({
    locale: browserLocale, // Use the determined locale
    fallbackLocale: defaultLocale, // Set fallback locale
    messages
});

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate); 

app.use(i18n);
app.use(router);
app.use(pinia);
app.mount('#app');
