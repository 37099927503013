<template>
  <div v-if="codes.length > 0">
    Codigos nao verificados por falta de rede:
    <ul>
      <li v-for="(code, index) in codes" :key="index" class="barcode-container">
        <img style="max-width: 180px;" :src="generateBarcodeURL(code)" alt="Barcode" class="barcode-image" />
        {{ code }}
      </li>
    </ul>
    <button @click="decodeAll" class="decode-button">Verificar códigos agora</button>
  </div>
  <div v-else>
    <p> ... </p>
  </div>
</template>
  
  <script>
  export default {
    name: 'LaterCode',
    data() {
      return {
        codes: []
      };
    },
    created() {
      this.loadSavedCodes();
    },
    methods: {
      generateBarcodeURL(code) {
        return `https://barcodeapi.org/api/ean13/${code}`;
      },
      formatCode(code) {
        return `${code.slice(0, 1)} ${code.slice(1, 7)} ${code.slice(7)}`;
      },
      loadSavedCodes() {
        const savedCodes = localStorage.getItem('later');
        if (savedCodes) {
          this.codes = JSON.parse(savedCodes);
        }
      },
      async decodeAll() {
        const codesToRemove = [];
        for (let code of this.codes) {
          try {
            await this.decodeNow(code);
            codesToRemove.push(code);
          } catch (error) {
            console.error(`Failed to decode code ${code}: ${error.message}`);
          }
        }
        this.removeDecodedCodes(codesToRemove);
      },
      async decodeNow(code) {
        console.log(`Decoding code: ${code}`);
        return new Promise((resolve) => setTimeout(resolve, 1000));
      },
      removeDecodedCodes(codesToRemove) {
        this.codes = this.codes.filter(code => !codesToRemove.includes(code));
        localStorage.setItem('later', JSON.stringify(this.codes));
      }
    }
  };
  </script>
 
 <style scoped>

.barcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0; /* Space between each code */
  height: 32px;
  overflow: hidden;
  width: 167px;
}

.barcode-image {
  position: relative;
  margin: 0px;
  border: 0px;
  padding: 10px 0px 10px 13px;
  margin-top: -72px;
}

.decode-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #2e77d1; /* Updated color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.decode-button:hover {
  background-color: #255fa8; /* Slightly darker shade of blue on hover */
}

.decode-button:active {
  background-color: #1d4c84; /* Even darker shade of blue when active */
  transform: scale(0.98);
}

</style>