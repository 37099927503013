<template>
  <div>
    <div class="burger-menu" @click="toggleMenu">
      <div :class="{ 'burger-bar': true, 'change': isOpen }"></div>
      <div :class="{ 'burger-bar': true, 'change': isOpen }"></div>
      <div :class="{ 'burger-bar': true, 'change': isOpen }"></div>
    </div>
    <div :class="{ 'side-bar': true, 'side-bar-open': isOpen }">
      <div class="menu-items">
        <router-link to="/" @click="toggleMenu"><i class="fas fa-home"></i></router-link>
        <router-link to="/scanner" @click="toggleMenu"><i class="fas fa-barcode"></i></router-link>
        <router-link to="/list" @click="toggleMenu"><i class="fas fa-shopping-cart"></i></router-link>
        <router-link to="/config" @click="toggleMenu"><i class="fas fa-cog"></i></router-link>
        <router-link to="/about" @click="toggleMenu"><i class="fas fa-info-circle"></i></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BurgerMenu',
  data() {
    return {
      isOpen: false,
      userGUID: '',
    };
  },
  created() {
    this.userGUID = this.getOrCreateGUID();
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    generateGUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    getOrCreateGUID() {
      // Check if GUID exists in local storage
      let guid = localStorage.getItem('user-guid');

      // If it doesn't exist, generate and store it
      if (!guid) {
        guid = this.generateGUID();
        localStorage.setItem('user-guid', guid);
      }
      return guid;
    }
    /*
    getTokenAndStore() {
      const socket = new WebSocket('wss://paodu.ro/service');

      socket.onopen = function(event) {
          socket.send('/token');
      };

      socket.onmessage = function(event) {
          try {
              const data = JSON.parse(event.data);
              if (data.token) {
                  localStorage.setItem('token', data.token);
                  socket.close();
              }
          } catch (e) {
              // Handle JSON parsing error if needed
          }
      };

      socket.onclose = function(event) {
          // Handle socket closure if needed
      };

      socket.onerror = function(error) {
          // Handle error if needed
      };
    } */
  }
}
</script>

<style scoped>
.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 100;
  position: fixed;
  right: 10px;
  bottom: 25%;
  /* Positioned 25% from the bottom */
}

.burger-bar {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease-in-out;
}

.change {
  transform: rotate(45deg);
}

.change:nth-child(2) {
  opacity: 0;
}

.change:nth-child(3) {
  transform: rotate(-45deg);
}

.side-bar {
  height: 100%;
  width: 150px;
  position: fixed;
  top: 0;
  right: -150px;
  background-color: #fff;
  overflow-y: auto;
  /* Ensure the sidebar is scrollable if content overflows */
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* Align items to the bottom */
  z-index: 98;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.side-bar-open {
  right: 0;
  background-color: #efefef;
  /* d5f7ee; */
}

.menu-items {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  /* Add space from the bottom */
}

.menu-items a {
  text-decoration: none;
  color: #3c3c3c;
  display: block;
  padding: 10px 20px;
}

.menu-items a:hover {
  background-color: #fdfdfd;
}

/* Media query to move the burger menu to the top for larger screens */
@media (min-width: 540px) {
  .burger-menu {
    bottom: auto;
    top: 10px;
    /* Positioned 10px from the top */
  }

  .side-bar {
    justify-content: flex-start;
    /* Align items to the top for larger screens */
  }

  .menu-items {
    padding-bottom: 0;
    /* Remove padding from the bottom for larger screens */
  }
}
</style>