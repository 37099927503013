<template>
  <CountryFetcher/>
  <BurgerMenu/>
  <router-view/>
</template>

<script>
import BurgerMenu from './components/BurgerMenu.vue';
import CountryFetcher from './components/CountryFetcher.vue'; 

export default {
  name: 'App',
  components: {
    BurgerMenu,
    CountryFetcher,
  }
};
</script>

<style>
body {
margin: 0;
background-color: #fdfdfd;
}

#app {
font-family: Avenir, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
color: #2c3e50;
}

router-link {
text-decoration: none;
color: #42b983;
}

router-link-active {
font-weight: bold;
}
</style>
