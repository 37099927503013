<template>
  <StreamBarcodeReader v-if="mediaStreamSupported" @decode="onDecode"></StreamBarcodeReader>
  <div class="code">
    <label for="barcode-input">{{ $t('barcode') }}</label>
    <input id="barcode-input" v-model="decodedText" class="input-field">
    <button @click="decodeNow" class="search-button"><i class="fas fa-search"></i>{{ $t('search_title')}}</button>
    
  </div>
  <div v-if="loading">Loading...</div>
  <div v-if="error">{{ error }}</div>
  <div v-if="data && data.price" class="card">
        <div class="card-header">
            <h2 class="price">{{ data.currency }} {{ data.price }}</h2>
            <p class="quantity">{{ data.quantity }}</p>
        </div>
        <div class="card-body">
            <p class="product-name">{{ data.name }}</p>
            <span class="brand">{{ data.brand }}</span>
        </div>
        <div class="card-footer">
            <p class="source">{{ data.source  }}</p>
            <button @click="saveItem()" class="save-button"><i class="fas fa-save"></i>{{ $t('save_title')}}</button>
        </div>
  </div>
<div class="card-container">
    <CardItem
      v-for="(product, index) in sortedProducts"
      :key="index"
      :gtin="product.gtin"
      :price="product.price"
      :name="product.name"
      :brand="product.brand"
      :quantity="product.quantity"
      :source="product.source"
      :diff="product.diff"
      :date="product.date"
      :currency="product.currency"
      @remove-item="removeItem(index)"
    />
  </div>

</template>

<script setup>
import CardItem from '@/components/CardItem.vue';
import { ref, computed, onMounted } from 'vue';
import { StreamBarcodeReader } from 'vue-barcode-reader';
const API_URL = 'https://paodu.ro';
const data = ref(null);
const decodedText = ref("");
const loading = ref(false);
const error = ref(null);
const products = ref([]);
const mediaStreamSupported = ref(false);
const GEO = ref("/");
const nearestID = ref("1");

const checkMediaStreamSupport = () => {
  mediaStreamSupported.value = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
};

const hasThirteenNumbers = (input) => {
  const regex = /^\d{13}$/;
  return regex.test(input);
};

const getCountry = async() => { 
  const storedCountry = localStorage.getItem('country');
  if (storedCountry) {
    GEO.value = '/' + storedCountry.toLowerCase(); 
    console.log('storage ', GEO.value ,' ',GEO.value.length);
    return storedCountry.toLowerCase();
  } else {
    const apiUrl = 'https://ipapi.co/json/';
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();
      const country = data.country.toLowerCase();
      if (country.length == 2){ 
      // Save to localStorage
      localStorage.setItem('country', country);
      GEO.value = '/' + country;
      } else {
        GEO.value = '/nd';
      }
      return country;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
}

const playBeep = (frequency, duration, startTime, context) => {
  const oscillator = context.createOscillator(); 
  const gainNode = context.createGain();

  oscillator.type = 'square';
  oscillator.frequency.setValueAtTime(frequency, context.currentTime + startTime);
  oscillator.connect(gainNode);
  gainNode.connect(context.destination);

  oscillator.start(context.currentTime + startTime);
  gainNode.gain.exponentialRampToValueAtTime(0.00001, context.currentTime + startTime + duration);
  oscillator.stop(context.currentTime + startTime + duration);
};

const playScanSound = () => {
  const context = new (window.AudioContext || window.webkitAudioContext)();
  playBeep(1500, 0.1, 0, context);    // First beep: 1500 Hz, 0.1 second
  playBeep(1000, 0.1, 0.1, context);  // Second beep: 1000 Hz, 0.1 second, starting after the first beep
};

const decodeNow = async () => {
  if (hasThirteenNumbers(decodedText.value)) {
    playScanSound();
    loading.value = true;
    error.value = null;
    data.value = null;
    try {
      const response = await fetch(`${API_URL}${GEO.value}/price/${decodedText.value}?store=${nearestID.value}`);
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const result = await response.json();
      data.value = result;
    } catch (err) {
      error.value = err.message;
    } finally {
      loading.value = false;
    }
  }
};

const saveItem = () => {
  if (data.value) {
    const exists = products.value.some(product => product.gtin === data.value.gtin);
    if (!exists) {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}/${String(now.getMonth() + 1).padStart(2, '0')}/${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
      products.value.push({ ...data.value, date: formattedDate });
      localStorage.setItem('products', JSON.stringify(products.value)); // Save to localStorage
      data.value = null;
      decodedText.value = "";
      localStorage.setItem('products', JSON.stringify(products.value)); // Save to localStorage
      
    }
  }
};

const removeItem = (index) => {
  products.value.splice(index, 1);
  localStorage.setItem('products', JSON.stringify(products.value)); 
};

const loadProductsFromLocalStorage = () => {
  const savedProducts = localStorage.getItem('products');
  if (savedProducts) {
    products.value = JSON.parse(savedProducts);
  }
};

const loadNearestID = () => {
      const savedID = localStorage.getItem('nearestid');
      if (savedID) {
        nearestID.value = savedID;
      }
};

const onDecode = async (text) => {
  if (text !== decodedText.value && hasThirteenNumbers(text)) {
    playScanSound();
    decodedText.value = text;
    loading.value = true;
    error.value = null;
    data.value = null;
    try {
      const response = await fetch(`${API_URL}${GEO.value}/price/${decodedText.value}?store=${nearestID.value}`);
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const result = await response.json();
      data.value = result;
    } catch (err) {
      error.value = err.message;
    } finally {
      loading.value = false;
    }
  }
};

const sortedProducts = computed(() => {
  return products.value.slice().sort((a, b) => a.source.localeCompare(b.source));
});

onMounted(() => {
  getCountry();
  loadNearestID();
  checkMediaStreamSupport();
  loadProductsFromLocalStorage(); // Load products on mount
});
</script>

<style scoped>
a {
  color: #42b983;
}
.information {
  margin-top: 0px;
}
.laser {
  display: none;
}
.overlay-element {
  display: none;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 16px;
  max-width: 200px;
  margin: 16px;
  font-family: Arial, sans-serif;
}

.information p {
  margin: 8px 0;
  font-size: 0.9rem;
  color: #333;
}

.information p:first-child {
  font-size: 24px;
  font-weight: bold;
  color: #27ae60;
}

.information p:last-child {
  font-size: 16px;
  font-weight: bold;
  color: #27ae60;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  padding: 16px;
}

.code {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 10px;
}
.code label {
  margin-left: 10px;
}
.search-button, .save-button, .delete-button {
  display: flex;
  align-items: center;
  background-color: #444;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.search-button i, .save-button i, .delete-button i {
  margin-right: 8px;
}

.delete-button {
  background-color: #FF4136;
  margin-top: 8px;
}

.input-field {
  max-width: 115px;
  height: calc(1.2em); /* Adjusted to match button height */
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}

.search-button, .save-button {
  height: calc(1.2em + 0.75rem + 2px); /* Adjusted to match input field height */
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.save-button {
 margin: auto;
}
</style>