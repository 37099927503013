<template>
    <div class="item-list">
        <h3 class="total-price">{{ $t('total') }}: {{ totalCurrency }}{{ totalPrice.toFixed(2) }}</h3>
        <button v-if="items.length > 0" :disabled="isUpdating" @click="refreshProducts" class="refresh-button"><i
                class="fas fa-sync-alt"></i> {{ isUpdating ? $t('waiting') :
                    $t('refresh') }}</button>
        <div v-for="(item, index) in items" :key="index" class="item-container">
            <div class="background-layer" :style="backgroundLayerStyle(index)">
                <span v-if="items[index].swipeOffset > 0" class="swipe-indicator left">+1</span>
                <span v-if="items[index].swipeOffset < 0" class="swipe-indicator right">-1</span>
            </div>
            <div class="item-box" @touchstart="startTouch($event, index)" @touchmove="moveTouch($event, index)"
                @touchend="endTouch(index)" :style="{
                    transform: swipeStyle(index),
                    transition: 'transform 0.5s ease',
                }">
                <div class="item-details">
                    <span class="item-name" @click="toggleDetails(index)">{{ item.name }} {{ item.quantity }}
                        <br>
                        <small class="source">{{ item.source }}</small>
                    </span>
                    <span class="item-quantity" @click="toggleDetails(index)">{{ item.count }} x</span>
                    <span class="item-price" @click="toggleDetails(index)">{{ item.currency }} {{ (item.price *
                        item.count).toFixed(2) }}</span>
                    <span class="item-arrow" @click="toggleDetails(index)"><i :class="getArrowClass(index)"></i></span>
                </div>
                <div v-if="expandedIndex === index" class="item-extra-details">
                    <p>{{ item.brand }}</p>
                    <div v-for="priceDetail in item.list" :key="priceDetail.source" class="price-detail-item">
                        <span class="store">{{ priceDetail.source }}: </span>
                        <span class="item-price">{{ item.currency }} {{ (priceDetail.price * item.count).toFixed(2) }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="sourceSums.length > 0" class="source-sum">
            <h3>{{ $t('total_per_store') }}:</h3>
            <div v-for="(sum, index) in sourceSums" :key="index" class="source-sum-item">
                <span>{{ sum.source }}:</span>
                <span>{{ totalCurrency }}{{ sum.total.toFixed(2) }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: "ItemList",
    data() {
        return {
            isUpdating: false,
            items: [],
            touchStartX: 0,
            touchEndX: 0,
            swipeThreshold: 50, // Minimum swipe distance to trigger action
            expandedIndex: null, // To track which item is expanded
        };
    },
    mounted() {
        this.loadItemsFromLocalStorage();
    },
    computed: {

        totalPrice() {
            return this.items.reduce((total, item) => total + item.price * item.count, 0);
        },
        totalCurrency() {
            return this.items.length > 0 ? this.items[0].currency : '';
        },
        sourceSums() {
            const sums = {};
            this.items.forEach(item => {
                if (!sums[item.source]) {
                    sums[item.source] = 0;
                }
                sums[item.source] += item.price * item.count;
            });
            return Object.keys(sums).map(source => ({
                source,
                total: sums[source]
            }));
        },
       
    },
    methods: {
        getArrowClass(index) {
            return this.expandedIndex === index ? 'fas fa-chevron-up' : 'fas fa-chevron-down';
        },
        async buildFilter(country) {
            let storedStoresData = localStorage.getItem(`stores_${country}`);

            if (storedStoresData === null) {
                try {
                    const response = await axios.get(`https://paodu.ro/${country}/stores`);
                    this.stores = response.data.map(store => ({
                        ...store,
                    }));
                    localStorage.setItem(`stores_${country}`, JSON.stringify(this.stores));
                    localStorage.setItem(`stores_timestamp_${country}`, new Date().getTime().toString());
                    storedStoresData = localStorage.getItem(`stores_${country}`);
                } catch (error) {
                    console.error('Error fetching stores:', error);
                }
            }

            if (storedStoresData) {
                const stores = JSON.parse(storedStoresData).sort((a, b) => a.id - b.id); // Ensure stores are sorted by id
                const filter = stores.map(item => {
                    if (item.enabled) {
                        if (item.compute && item.nearest !== null) {
                            return item.nearest;
                        }
                        return '1';
                    } else {
                        return '0';
                    }
                }).join(',');

                return '?filter=' + filter;
            }

            return '';
        },
        loadItemsFromLocalStorage() {
            const storedProducts = localStorage.getItem('products');
            if (storedProducts) {
                this.items = JSON.parse(storedProducts).products.map(product => ({
                    ...product,
                    swipeOffset: 0, // Initialize swipeOffset for each product
                    count: 1, // Initialize count
                    price: parseFloat(product.price), // Ensure price is a number
                }));
            }
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async refreshProducts() {
            this.isUpdating = true; // Disable the button

            const country = localStorage.getItem('country');
            const filter = await this.buildFilter(country);
            const userGUID = localStorage.getItem('user-guid');

            for (const product of this.items) {
                try {

                    const controller = new AbortController();
                    const timeoutId = setTimeout(() => {
                        controller.abort();
                    }, 4000);

                    const response = await fetch(`https://paodu.ro/${country}/price/${product.gtin}${filter}`, {
                        signal: controller.signal,
                        headers: { 'X-User-GUID': userGUID },
                    });

                    clearTimeout(timeoutId);

                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }

                    let tmpProduct = await response.json();

                    if (!tmpProduct || tmpProduct.price === undefined || tmpProduct.price === null || tmpProduct.price === '') {
                        continue;
                    }

                    if (product.gtin === tmpProduct.gtin) {
                        Object.assign(product, {
                            price: tmpProduct.price,
                            name: tmpProduct.name,
                            brand: tmpProduct.brand,
                            quantity: tmpProduct.quantity,
                            source: tmpProduct.source,
                            diff: tmpProduct.diff,
                            list: tmpProduct.list
                        });
                    }


                } catch (error) {
                    console.log(error);
                }

                await this.sleep(1500);
            }

            this.saveItemsToLocalStorage();
            this.isUpdating = false; // Re-enable the button
            console.log('All products have been updated');
        },
        startTouch(event) {
            this.touchStartX = event.changedTouches[0].screenX;
        },
        moveTouch(event, index) {
            this.touchEndX = event.changedTouches[0].screenX;
            const swipeDistance = this.touchEndX - this.touchStartX;
            this.items[index].swipeOffset = swipeDistance;
        },

        endTouch(index) {
            const swipeDistance = this.items[index].swipeOffset;
            if (Math.abs(swipeDistance) > this.swipeThreshold) {
                if (swipeDistance > 0) {
                    // Increase quantity
                    this.items[index].count += 1;
                } else if (swipeDistance < 0) {
                    // Decrease quantity, but not below 1
                    //this.items[index].quantity = Math.max(1, this.items[index].quantity - 1);
                    this.items[index].count -= 1;
                    if (this.items[index].count <= 0) {
                        this.removeItem(index); // Remove item if quantity reaches 0
                        return;
                    }
                }
                this.items[index].swipeOffset = 0;
                this.saveItemsToLocalStorage();
            }
            // Reset swipeOffset after handling the swipe
            this.items[index].swipeOffset = 0;

        },
        removeItem(index) {
            this.items.splice(index, 1);
            this.saveItemsToLocalStorage();
        },
        saveItemsToLocalStorage() {
            localStorage.setItem('products', JSON.stringify({ products: this.items }));
        },
        swipeStyle(index) {
            return `translateX(${this.items[index].swipeOffset}px)`;
        },
        backgroundLayerStyle(index) {
            if (this.items[index].swipeOffset > 0) {
                return { backgroundColor: '#28a745' }; // Green for swiping right (increase)
            } else if (this.items[index].swipeOffset < 0) {
                return { backgroundColor: '#e74c3c' }; // Red for swiping left (decrease)
            }
            return { backgroundColor: '#ffffff' }; // Default background
        },
        toggleDetails(index) {
            this.expandedIndex = this.expandedIndex === index ? null : index;
        },
    },
};
</script>

<style scoped>
.item-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    margin-right: 35px;

}

.item-container {
    position: relative;
    overflow: hidden;
}

.background-layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-radius: 6px;
    transition: background-color 250ms ease;
}

.swipe-indicator {
    color: white;
    font-size: 1.2em;
    font-weight: bold;
    line-height: 50px;
    width: 100%;
}

.swipe-indicator.left {
    margin-left: 0px;
    text-align: left;
}

.swipe-indicator.right {
    margin-right: 0px;
    text-align: right;
}

.item-box {
    position: relative;
    z-index: 2;
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.item-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-name {
    flex: 1;
    text-align: left;
    font-size: 0.9em;
    color: #333;
}

.item-quantity {
    width: 25px;
    text-align: center;
    font-size: 0.8em;
    color: #555;
}

.item-price {
    width: 60px;
    text-align: right;
    font-size: 0.9em;
    color: #333;
    font-weight: bold;
}

.item-extra-details {
    margin-top: 10px;
    font-size: 0.9em;
    color: #333;
}

.item-arrow {
    margin-left: 15px;
    margin-right: 0;
    color: #777;
}

.source-sum {
    margin-top: 20px;
    font-size: 1.1em;
    color: #333;
}

.source-sum-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.empty-cart-message {
    text-align: center;
    font-size: 1.2em;
    color: #999;
}

.price-detail-item {
    text-align: right;
}

.price-detail-item .store {
    width: 170px;
}

.price-detail-item .item-price {
    width: 50px;
}

.refresh-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #2e77d1;
    /* Updated color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.refresh-button:hover {
    background-color: #255fa8;
    /* Slightly darker shade of blue on hover */
}

.refresh-button:active {
    background-color: #1d4c84;
    /* Even darker shade of blue when active */
    transform: scale(0.98);
}

.source {
    color: #a0a0a0;
}
</style>