<template>
    <div class="card">
      <div class="information">
        <p> {{ currency }} {{ price }}</p>
        <p>{{ name }}</p>
        <p>{{ brand }}</p>
        <p>{{ quantity }}</p>
        <p>{{ source }}</p>
        <p>{{ diff }}</p>
        <p>{{ date }}</p>
      </div>
    <div class="button-container">
      <button @click="$emit('remove-item')" class="delete-button"><i class="fas fa-trash"></i></button>
    </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'CardItem',
    props: {
      gtin: String,
      price: Number,
      name: String,
      brand: String,
      quantity: String,
      source: String,
      diff: String,
      date: String,
      currency: String
    }
  };
  </script>
  
  <style scoped>
  .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 16px;
    max-width: 200px;
    min-width: 150px;
    margin: 16px;
    font-family: Arial, sans-serif;
  }
  
  .information p {
    margin: 8px 0;
    font-size: 14px;
    color: #333;
  }
  
  .information p:first-child {
    font-size: 24px;
    font-weight: bold;
    color: #27ae60;
  }
  
  .information p:last-child {
    font-size: 10px;
    font-weight: bold;
    color: #c2c2c2;
  }
  
  .delete-button {
  background-color: #FF4136;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
  .button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}

.delete-button i {
  margin-right: 8px;
}
  </style>
  