<template>
    <div class="terms">
      <h2>{{ $t('terms_and_conditions') }}</h2>
      <h3>{{ $t('terms_and_conditions_0') }}</h3>
      <p>{{ $t('terms_and_conditions_p0') }}</p>
      <h3>{{ $t('terms_and_conditions_1') }}</h3>
      <p>{{ $t('terms_and_conditions_p1') }}</p>
      <h3>{{ $t('terms_and_conditions_2') }}</h3>
      <h4>{{ $t('terms_and_conditions_21') }}</h4>
      <p>{{ $t('terms_and_conditions_p21') }}</p>
      <h4>{{ $t('terms_and_conditions_22') }}</h4>
      <p>{{ $t('terms_and_conditions_p22') }}</p>
      <h3>{{ $t('terms_and_conditions_3') }}</h3> 
      <p>{{ $t('terms_and_conditions_p3') }}</p>
      <h3>{{ $t('terms_and_conditions_4') }}</h3>
      <p>{{ $t('terms_and_conditions_p4') }}</p> 
      <h3>{{ $t('terms_and_conditions_5') }}</h3>
      <p>{{ $t('terms_and_conditions_p5') }}</p> 
      <h3>{{ $t('terms_and_conditions_6') }}</h3>
      <p>{{ $t('terms_and_conditions_p6') }}</p> 
      <h3>{{ $t('terms_and_conditions_7') }}</h3> 
      <p>{{ $t('terms_and_conditions_p7') }}<a href="mailto:info@paodu.ro?subject=PãoDuro">info@paodu.ro</a></p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </div>
  
  </template>
  
  <style scoped>
  .terms {
    text-align: left;
    margin-left: 30px;
    margin-right: 45px;
    margin-bottom: 20px;
  }
  
  
  .terms a {
    font-weight: bold;
    color: #444;
    text-decoration: none;
  }
  
  .terms a:hover,
  .terms a:visited {
    color: #444;
  }
  
  .terms a:hover {
    text-decoration: underline;
  }
  </style>