<template>
  <div class="about">
    <h2>{{ $t('information') }}</h2>
    <p>{{ $t('info_p1') }}</p>
    <p>{{ $t('info_p2') }}</p>
    <p>{{ $t('info_p3') }}</p>
    <p>{{ $t('info_p4') }}</p>
    <ul>
      <li>{{ $t('info_p4_1') }}</li>
      <li>{{ $t('info_p4_2') }}</li>
      <li>{{ $t('info_p4_3') }}</li>
      <li>{{ $t('info_p4_4') }}</li>
      <li>{{ $t('info_p4_5') }}</li>
      <li>{{ $t('info_p4_6') }}</li>
    </ul>

    <p>{{ $t('info_p5') }}</p>

    <p>{{ $t('info_p6') }}</p>
    <p>{{ $t('info_p7') }}</p>
    <p>{{ $t('info_p8') }}</p>
    <p>{{ $t('info_p9') }} <a href="mailto:info@paodu.ro?subject=PãoDuro">info@paodu.ro</a></p>
    <p>{{ $t('info_p10') }}</p>
    <p>{{ $t('info_p11') }}</p>
  </div>

</template>

<style scoped>
.about {
  text-align: left;
  margin-left: 30px;
  margin-right: 45px;
  margin-bottom: 20px;
}


.about a {
  font-weight: bold;
  color: #444;
  text-decoration: none;
}

.about a:hover,
.about a:visited {
  color: #444;
}

.about a:hover {
  text-decoration: underline;
}
</style>