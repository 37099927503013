<template>
    <div class="shoppinglist">
      <h2>{{ $t('shopping_list') }}</h2>
      <div class="card-container">
      <CardItem
        v-for="(product, index) in products"
        :key="index"
        :gtin="product.gtin"
        :price="product.price"
        :name="product.name"
        :brand="product.brand"
        :quantity="product.quantity"
        :source="product.source"
        :diff="product.diff"
        :date="product.date"
        :currency="product.currency"
        @remove-item="removeProduct(index)"
      />
  </div>
    </div>
</template>

<script>
import { useProductStore } from '@/stores/productStore';
import CardItem from '@/components/CardItem.vue';

export default {
  name: 'CartView', 
  computed: {
    products() {
      const store = useProductStore();
      return store.products;
    },
    totalProducts() {
      const store = useProductStore();
      return store.totalProducts;
    },
  },
  methods: {
    removeProduct(index){
      const store = useProductStore();
      store.removeProductByIndex(index);
    },
  },
  components: {
    CardItem,
  },
  data() {
    return {
      
    }
  }
}
</script>

<style scoped>

.shoppinglist {
    text-align: left;
    margin-left: 30px;
    margin-right: 45px;
    margin-bottom: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 6px;
  padding: 6px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 7px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 6px;
  width: 200px;
  overflow: hidden;
  margin: 6px;
  font-family: Arial, sans-serif;
}

</style>