<template>
  <div class="home">
    <img alt="PaoDuro logo" class="logo" src="../assets/logo.svg">
    <div class="hello">
    <h2>{{ $t('welcome') }}</h2>
    <p>
      {{ $t('description') }}
    </p>
    </div>
    <div class="container">
      <router-link to="/scanner"><button class="barcode-button">{{ $t('compare_prices_now') }}</button></router-link>
    </div>
    <div class="terms-link">
      <router-link to="/terms">{{ $t('terms_and_conditions') }}</router-link>
    </div>
  </div>
</template>

<style scoped>
.logo {
    margin-top: 50px;
    max-width: 30%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 50%; /* Make the image circular */
}

.container {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}

.barcode-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #28a745; /* Green color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: -1;
}

.barcode-button:hover {
  background-color: #218838; /* Darker green on hover */
}

.barcode-button:active {
  background-color: #1e7e34; /* Even darker green when active */
  transform: scale(0.98);
}

.terms-link {
  position: fixed;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  opacity: 0.5; /* Make it discreet */
}

.terms-link a {
  color: #000; /* You can adjust this color as needed */
  text-decoration: none;
}

.terms-link a:hover {
  opacity: 0.8; /* Slight hover effect */
}

@media (max-width: 540px) {
  .container {
    position: fixed;
    bottom: 20%;
  }

  .terms-link {
    bottom: 10px;
  }

  @media (max-height: 555px) {
    .container {
      bottom: 0;
      position: relative;
    }
    .terms-link {
       bottom: 0px;
       position: relative;
    }
  }
}

</style>

<script setup>

</script>
