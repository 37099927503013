/* import { search } from "core-js/fn/symbol"; */

export default {
  en: {
    welcome: 'Welcome to PãoDuro',
    description: 'Compare supermarket prices online.',
    compare_prices_now: 'Start now!',
    barcode: 'Barcode:',
    product_added: 'Product added to cart!',
    information: 'Information',
    info_p1: 'Large food distribution chains commonly use loyalty cards to gather sales information. This data is then analyzed to gain insights into customer behaviour, manage prices, control inventory, and minimize operational costs.',
    info_p2: 'Despite the relatively low value of individual items, the collective monthly sum spent on groceries can significantly impact a family\'s budget.',
    info_p3: 'Just as we compare prices across different stores when making high-value purchases, it is also beneficial to do the same for low-value items. However, this can be challenging as prices fluctuate frequently.',
    info_p4: 'Using a tool that displays the lowest prices is helpful, but it\'s also important to implement additional money-saving strategies such as:',
    info_p4_1: 'Setting a budget.',
    info_p4_2: 'Creating a shopping list.',
    info_p4_3: 'Taking advantage of promotions and coupons.',
    info_p4_4: 'Comparing prices per unit.',
    info_p4_5: 'Avoiding frequent small purchases.',
    info_p4_6: 'Participating in collective purchasing groups can also be beneficial.',
    info_p5: 'Adopting these practices allows you to manage your budget and maximize your grocery savings.',
    info_p6: '',
    info_p7: 'Prices used in the comparison may differ from those in physical stores.',
    info_p8: 'It\'s always advisable to use the information from this tool critically.',
    info_p9: 'We’d love to hear from you! For feedback, suggestions, or questions, contact us at',
    info_p10: 'Your input helps us create a better experience.',
    info_p11: 'Thank you, and happy shopping!',
    search_title: 'Search',
    save_title: 'Save',
    settings: 'Settings',
    settings_find_stores: 'Finding the closest stores',
    settings_note: 'Note: Your privacy is protected, and your location will not leave your device.',
    loading: 'Loading',
    store: 'Store',
    stores: 'Stores',
    stores_not_found: 'No store found!',
    location_required: 'Requires the usage of your current location.',
    waiting: 'Please wait ...',
    no_matches_found: 'No matches found',
    review_configs: 'Kindly review the configurations here',
    start_scanner: 'start scanner',
    stop_scanner: 'stop scanner',
    shopping_list: 'Shopping List',
    error_cam: 'Please allow camera access to start scanning barcodes. Your privacy is our priority; the camera will only be used for scanning. No images will be transmitted from your device.',
    total: 'Total',
    total_per_store: 'Total per store',
    zoom_in_on_the_barcode: 'Zoom in on the barcode',
    refresh: 'Refresh',
    network_error: 'Network error',
    terms_and_conditions: 'Terms and Conditions',
    terms_and_conditions_0: 'Last Updated: September, 1st 2024',
    terms_and_conditions_p0: 'Welcome to our web application ("PãoDuro"). These Terms and Conditions ("Terms") govern your use of the Application. By using the Application, you agree to these Terms. Please read them carefully before proceeding.',
    terms_and_conditions_1: '1. Purpose of the Application',
    terms_and_conditions_p1: 'Our Application allows users to access certain features that use the device’s camera and geolocation services, as necessary for the functionalities provided. The use of these features is essential for the user experience, but all data generated through these features is processed locally on the user’s device and is not transmitted or stored on external servers.',
    terms_and_conditions_2: '2. Privacy and Data Security',
    terms_and_conditions_21: '2.1 Camera Usage',
    terms_and_conditions_p21: 'When using the Application, you may allow access to your device’s camera. Any images or videos captured through the camera are processed locally on your device. We do not collect, transmit, or store any images, videos, or other data generated by the camera.',
    terms_and_conditions_22: '2.2 Geolocation Usage',
    terms_and_conditions_p22: 'With your consent, the Application may access your geographic location. This information is used exclusively to provide certain services or features of the Application. Like camera data, location information is neither stored nor transmitted to external servers.',
    terms_and_conditions_3: '3. User Consent',
    terms_and_conditions_p3: 'Before using the camera and geolocation features of the device, your explicit consent will be requested. Access to these features can be revoked at any time through your device\'s settings.',
    terms_and_conditions_4: '4. User Responsibility',
    terms_and_conditions_p4: 'You are responsible for maintaining the security of your device and ensuring that the camera and geolocation permissions are configured according to your preferences.',
    terms_and_conditions_5: '5. Limitation of Liability',
    terms_and_conditions_p5: 'To the extent permitted by applicable law, the Application and its developers are not liable for any direct or indirect damages that may result from the use of the Application, including but not limited to device malfunctions, data loss, or privacy breaches arising from improper device configurations or misuse.',
    terms_and_conditions_6: '6. Changes to the Terms',
    terms_and_conditions_p6: 'We may revise these Terms periodically to reflect changes in functionalities or applicable law. We recommend that you review this page regularly to stay informed of any updates.',
    terms_and_conditions_7: '7. Contact',
    terms_and_conditions_p7: 'If you have any questions or concerns about the use of the camera, geolocation, or other privacy-related matters, please contact us at: ',
  },
  fr: {
    welcome: 'Bienvenue à PãoDuro!',
    description: 'Comparez les prix des supermarchés en ligne.',
    compare_prices_now: 'Commencez maintenant!',
    barcode: 'Code à barre:',
    product_added: 'Produit ajouté au panier!',
    information: 'Information',
    info_p1: 'Les grandes chaînes de distribution alimentaire utilisent couramment des cartes de fidélité pour recueillir des informations sur les ventes. Ces données sont ensuite analysées pour obtenir des informations sur le comportement des clients, gérer les prix, contrôler les stocks et minimiser les coûts opérationnels.',
    info_p2: 'Malgré la valeur relativement faible des articles individuels, la somme mensuelle collective dépensée en épicerie peut avoir un impact significatif sur le budget d\'une famille.',
    info_p3: 'Tout comme nous comparons les prix entre différents magasins lorsque nous effectuons des achats de grande valeur, il est également avantageux de faire de même pour les articles de faible valeur. Cependant, cela peut être difficile car les prix fluctuent fréquemment.',
    info_p4: 'L\'utilisation d\'un outil qui affiche les prix les plus bas est utile, mais il est également important de mettre en œuvre des stratégies supplémentaires d\'économie d\'argent telles que :',
    info_p4_1: 'Établir un budget.',
    info_p4_2: 'Créer une liste de courses.',
    info_p4_3: 'Profiter des promotions et des coupons.',
    info_p4_4: 'Comparer les prix par unité.',
    info_p4_5: 'Éviter les petits achats fréquents.',
    info_p4_6: 'La participation à des groupes d\'achat collectifs peut également être bénéfique.',
    info_p5: 'L\'adoption de ces pratiques vous permet de gérer votre budget et de maximiser vos économies d\'épicerie.',
    info_p6: '',
    info_p7: 'Les prix utilisés dans la comparaison peuvent différer de ceux pratiqués en magasin.',
    info_p8: 'Il est toujours conseillé d\'utiliser les informations de cet outil avec discernement.',
    info_p9: 'Nous aimerions avoir de vos nouvelles ! Pour nous faire part de vos commentaires, suggestions ou questions, contactez-nous à l\'adresse',
    info_p10: 'Vos commentaires nous aident à créer une meilleure expérience.',
    info_p11: 'Merci et bon shopping !',
    search_title: 'Recherche',
    save_title: 'Sauvegarder',
    settings: 'Paramètres',
    settings_find_stores: 'Recherche des magasins les plus proches',
    settings_note: 'Remarque: votre vie privée est protégée et votre position ne quittera pas votre appareil.',
    loading: 'Chargement',
    store: 'Magasin',
    stores: 'Magasins',
    stores_not_found: 'Aucun magasin trouvé!',
    location_required: 'Nécessite l\'utilisation de votre emplacement actuel.',
    waiting: 'Veuillez patienter ...',
    no_matches_found: 'Aucune correspondance trouvée',
    review_configs: 'Veuillez vérifier les configurations ici',
    start_scanner: 'démarrer le scanner',
    stop_scanner: 'arrêter le scanner',
    shopping_list: 'Liste de courses',
    error_cam: 'Veuillez autoriser l\'accès à la caméra pour commencer à scanner les codes-barres. Votre vie privée est notre priorité ; la caméra ne sera utilisée que pour la numérisation. Aucune image ne sera transmise depuis votre appareil.',
    total: 'Total',
    total_per_store: 'Total par magasin',
    zoom_in_on_the_barcode: 'Zoomer sur le code barre',
    refresh: 'Mise à jour',
    network_error: 'Erreur réseau',
    terms_and_conditions: 'Conditions Générales',
    terms_and_conditions_0: 'Dernière mise à jour : 1er septembre 2024',
    terms_and_conditions_p0: 'Bienvenue sur notre application web ("Application"). Ces Conditions Générales d\'Utilisation ("Conditions") régissent votre utilisation de l\'Application. En utilisant l\'Application, vous acceptez ces Conditions. Veuillez les lire attentivement avant de continuer.',
    terms_and_conditions_1: '1. Objet de l\'Application',
    terms_and_conditions_p1: 'Notre Application permet aux utilisateurs d\'accéder à certaines fonctionnalités qui utilisent la caméra et les services de géolocalisation de leur appareil, conformément aux fonctionnalités proposées par l\'Application. L\'utilisation de ces fonctionnalités est essentielle à l\'expérience utilisateur, mais toutes les données générées par ces fonctionnalités sont traitées localement sur l\'appareil de l\'utilisateur et ne sont ni transmises ni stockées sur des serveurs externes.',
    terms_and_conditions_2: '2. Confidentialité et Sécurité des Données',
    terms_and_conditions_21: '2.1 Utilisation de la Caméra',
    terms_and_conditions_p21: 'En utilisant l\'Application, vous pouvez autoriser l\'accès à la caméra de votre appareil. Les images ou vidéos capturées via la caméra sont traitées localement sur votre appareil. Nous ne collectons, ne transmettons ni ne stockons aucune image, vidéo ou autre donnée générée par la caméra.',
    terms_and_conditions_22: '2.2 Utilisation de la Géolocalisation',
    terms_and_conditions_p22: 'Avec votre consentement, l\'Application peut accéder à votre position géographique. Ces informations sont utilisées uniquement pour fournir certains services ou fonctionnalités de l\'Application. Comme pour les données de la caméra, les informations de géolocalisation ne sont ni stockées ni transmises à des serveurs externes.',
    terms_and_conditions_3: '3. Consentement de l\'Utilisateur',
    terms_and_conditions_p3: 'Avant d\'utiliser les fonctionnalités de la caméra et de la géolocalisation de l\'appareil, votre consentement explicite sera requis. L\'accès à ces fonctionnalités peut être révoqué à tout moment via les paramètres de votre appareil.',
    terms_and_conditions_4: '4. Responsabilité de l\'Utilisateur',
    terms_and_conditions_p4: 'Vous êtes responsable de la sécurité de votre appareil et devez vous assurer que les autorisations d\'accès à la caméra et à la géolocalisation sont configurées conformément à vos préférences.',
    terms_and_conditions_5: '5. Limitation de Responsabilité',
    terms_and_conditions_p5: 'Dans la mesure permise par la loi applicable, l\'Application et ses développeurs ne sont pas responsables des dommages directs ou indirects pouvant résulter de l\'utilisation de l\'Application, y compris, mais sans s\'y limiter, les dysfonctionnements de l\'appareil, la perte de données ou les violations de la vie privée découlant d\'une mauvaise configuration ou d\'une utilisation incorrecte de l\'appareil.',
    terms_and_conditions_6: '6. Modifications des Conditions',
    terms_and_conditions_p6: 'Nous pouvons réviser ces Conditions périodiquement pour refléter les changements des fonctionnalités ou des lois applicables. Nous vous recommandons de consulter régulièrement cette page pour être informé(e) des mises à jour.',
    terms_and_conditions_7: '7. Contact',
    terms_and_conditions_p7: 'Si vous avez des questions ou des préoccupations concernant l\'utilisation de la caméra, de la géolocalisation ou d\'autres aspects liés à la confidentialité, veuillez nous contacter à : ',
  },
  es: {
    welcome: '¡Bienvenidos a PãoDuro!',
    description: 'Compara precios de supermercados online.',
    compare_prices_now: '¡Empieza ahora!',
    barcode: 'Código de barras:',
    product_added: '¡Producto añadido al carrito!',
    information: 'Información',
    info_p1: 'Las grandes cadenas de distribución de alimentos suelen utilizar tarjetas de fidelidad para recopilar información sobre las ventas. Estos datos se analizan para obtener información sobre el comportamiento de los clientes, gestionar los precios, controlar el inventario y minimizar los costes operativos.',
    info_p2: 'A pesar del valor relativamente bajo de los artículos individuales, la suma mensual colectiva gastada en comestibles puede afectar significativamente el presupuesto de una familia.',
    info_p3: 'Así como comparamos precios en diferentes tiendas cuando hacemos compras de alto valor, también es beneficioso hacer lo mismo con los artículos de bajo valor. Sin embargo, esto puede ser un desafío ya que los precios fluctúan con frecuencia.',
    info_p4: 'El uso de una herramienta que muestre los precios más bajos es útil, pero también es importante implementar estrategias adicionales para ahorrar dinero, como:',
    info_p4_1: 'Establecer un presupuesto.',
    info_p4_2: 'Crear una lista de compras.',
    info_p4_3: 'Aprovechar promociones y cupones.',
    info_p4_4: 'Comparar precios por unidad.',
    info_p4_5: 'Evitar compras pequeñas frecuentes.',
    info_p4_6: 'Participar en grupos de compra colectiva también puede ser beneficioso.',
    info_p5: 'La adopción de estas prácticas le permite administrar su presupuesto y maximizar sus ahorros en comestibles.',
    info_p6: '',
    info_p7: 'Los precios utilizados en la comparación pueden diferir de los de las tiendas físicas.',
    info_p8: 'Siempre es recomendable utilizar la información de esta herramienta de forma crítica.',
    info_p9: '¡Nos encantaría saber de ti! Si tienes comentarios, sugerencias o preguntas, contáctanos en',
    info_p10: 'Tus comentarios nos ayudan a crear una mejor experiencia.',
    info_p11: '¡Gracias y felices compras!',
    search_title: 'Buscar',
    save_title: 'Ahorrar',
    settings: 'Configuración',
    settings_find_stores: 'Encontrar las tiendas más cercanas',
    settings_note: 'Nota: Tu privacidad está protegida y tu ubicación no saldrá de tu dispositivo.',
    loading: 'Cargando',
    store: 'Tienda',
    stores: 'Tiendas',
    stores_not_found: '¡No se encontró ninguna tienda!',
    location_required: 'Requiere el uso de su ubicación actual.',
    waiting: 'Por favor espere ...',
    no_matches_found: 'No se encontraron coincidencias',
    review_configs: 'Revise las configuraciones aquí',
    start_scanner: 'iniciar escáner',
    stop_scanner: 'detener el escáner',
    shopping_list: 'Lista de compras',
    error_cam: 'Permita el acceso a la cámara para comenzar a escanear códigos de barras. Su privacidad es nuestra prioridad; la cámara solo se utilizará para escanear. No se transmitirán imágenes desde su dispositivo.',
    total: 'Total',
    total_per_store: 'Total por tienda',
    zoom_in_on_the_barcode: 'Ampliar el código de barras',
    refresh: 'Actualizar',
    network_error: 'Error de red',
    terms_and_conditions: 'Términos y Condiciones',
    terms_and_conditions_0: 'Última actualización: 1 de septiembre de 2024',
    terms_and_conditions_p0: 'Bienvenido/a a nuestra aplicación web ("Aplicación"). Estos Términos y Condiciones ("Términos") rigen el uso de la Aplicación. Al utilizar la Aplicación, aceptas estos Términos. Por favor, léelos cuidadosamente antes de continuar.',
    terms_and_conditions_1: '1. Propósito de la Aplicación',
    terms_and_conditions_p1: 'Nuestra Aplicación permite a los usuarios acceder a ciertas funcionalidades que utilizan la cámara y los servicios de geolocalización del dispositivo, de acuerdo con los objetivos definidos por la propia Aplicación. El uso de estas funcionalidades es esencial para la experiencia del usuario, pero los datos generados por estas funciones se procesan localmente en el dispositivo del usuario y no se transmiten ni almacenan en servidores externos.',
    terms_and_conditions_2: '2. Privacidad y Seguridad de los Datos',
    terms_and_conditions_21: '2.1 Uso de la Cámara',
    terms_and_conditions_p21: 'Al utilizar la Aplicación, puedes permitir el acceso a la cámara de tu dispositivo. Las imágenes o videos capturados a través de la cámara se procesan localmente en tu dispositivo. No recopilamos, transmitimos ni almacenamos ninguna imagen, video u otros datos generados por la cámara.',
    terms_and_conditions_22: '2.2 Uso de la Geolocalización',
    terms_and_conditions_p22: 'Con tu consentimiento, la Aplicación puede acceder a tu ubicación geográfica. Esta información se utiliza exclusivamente para proporcionar ciertos servicios o funcionalidades de la Aplicación. Al igual que los datos de la cámara, la información de geolocalización no se almacena ni se transmite a servidores externos.',
    terms_and_conditions_3: '3. Consentimiento del Usuario',
    terms_and_conditions_p3: 'Antes de utilizar las funciones de la cámara y la geolocalización del dispositivo, se te solicitará tu consentimiento explícito. El acceso a estas funciones puede ser revocado en cualquier momento desde la configuración de tu dispositivo.',
    terms_and_conditions_4: '4. Responsabilidad del Usuario',
    terms_and_conditions_p4: 'Eres responsable de mantener la seguridad de tu dispositivo y de asegurarte de que los permisos de la cámara y la geolocalización estén configurados según tus preferencias.',
    terms_and_conditions_5: '5. Limitación de Responsabilidad',
    terms_and_conditions_p5: 'En la medida en que la ley aplicable lo permita, la Aplicación y sus desarrolladores no serán responsables por daños directos o indirectos que puedan derivarse del uso de la Aplicación, incluyendo, pero no limitándose a, fallos del dispositivo, pérdida de datos o violaciones de la privacidad derivadas de configuraciones incorrectas o uso indebido del dispositivo.',
    terms_and_conditions_6: '6. Cambios en los Términos',
    terms_and_conditions_p6: 'Podemos revisar estos Términos periódicamente para reflejar cambios en las funcionalidades o en la legislación aplicable. Te recomendamos que revises esta página regularmente para mantenerte informado/a de cualquier actualización.',
    terms_and_conditions_7: '7. Contacto',
    terms_and_conditions_p7: 'Si tienes alguna duda o inquietud sobre el uso de la cámara, la geolocalización u otros aspectos relacionados con la privacidad, por favor contáctanos a través del correo electrónico: ',
  },
  pt: {
    welcome: 'Bem-vindo(a) ao PãoDuro',
    description: 'Compare preços online de supermercados.',
    compare_prices_now: 'Comece agora!',
    barcode: 'Código de barras:',
    product_added: 'Produto adicionado ao carrinho!',
    information: 'Informações',
    info_p1: 'As grandes cadeias de distribuição alimentar frequentemente utilizam cartões de fidelização para recolher informações sobre as vendas. Esses dados são processados e utilizados não apenas para conhecer melhor os clientes, mas também para gerir preços, controlar stock, reduzir custos operacionais, entre outros.',
    info_p2: 'A maioria dos clientes, porém, não possui ou não utiliza ferramentas que os ajudem a escolher os melhores preços. Embora sejam produtos de baixo valor individual, o total mensal dessas compras é bastante significativo, representando uma das maiores fatias do orçamento familiar.',
    info_p3: 'Assim como procuramos comparar preços em várias lojas ao adquirir um produto de elevado valor, também devemos fazer o mesmo para produtos de baixo valor. Considerando que os preços podem mudar diariamente, essa tarefa pode ser desafiante.',
    info_p4: 'Utilizar uma ferramenta que mostre o preço mais baixo é muito útil, mas requer a implementação de outras estratégias para economizar, tais como:',
    info_p4_1: 'Estabelecer um orçamento.',
    info_p4_2: 'Fazer uma lista de compras.',
    info_p4_3: 'Aproveitar promoções e cupões.',
    info_p4_4: 'Comparar sempre os preços por quilo, litro ou unidade.',
    info_p4_5: 'Evitar compras frequentes.',
    info_p4_6: 'Participar de grupos de compras coletivas.',
    info_p5: 'Implementar essas práticas pode ajudar significativamente na gestão eficiente do orçamento e na maximização das suas poupanças em compras de supermercado.',
    info_p6: 'Nesta ferramenta são usadas várias fontes de preços que são atualizados todos os dias.',
    info_p7: 'Os preços utilizados na comparação podem ser diferentes dos preços das lojas físicas.',
    info_p8: 'Utilize a informação desta ferramenta sempre com espírito crítico.',
    info_p9: 'Gostaríamos de ouvi-lo! Para feedback, sugestões ou perguntas, entre em contato conosco em',
    info_p10: 'A sua opinião nos ajudará a criar uma experiência melhor.',
    info_p11: 'Obrigado e boas compras!',
    search_title: 'Pesquisar',
    save_title: 'Adicionar',
    settings: 'Configurações',
    settings_find_stores: 'Usar lojas mais próximas',
    settings_note: 'Nota: A sua privacidade está protegida e a sua localização não sairá do seu dispositivo.',
    loading: 'Carregando',
    store: 'Loja',
    stores: 'Lojas',
    stores_not_found: 'Nenhuma loja encontrada!',
    location_required: 'Requer a utilização da sua localização atual.',
    waiting: 'Por favor aguarde ...',
    no_matches_found: 'Nenhuma correspondência encontrada',
    review_configs: 'Por favor, reveja as configurações aqui',
    start_scanner: 'Iniciar scanner',
    stop_scanner: 'Parar scanner',
    shopping_list: 'Lista de compras',
    error_cam: 'Permita o acesso à câmara para iniciar a leitura de códigos de barras. A sua privacidade é a nossa prioridade; a câmara será utilizada apenas para digitalização. Nenhuma imagem será transmitida a partir do seu dispositivo.',
    total: 'Total',
    total_per_store: 'Total por loja',
    zoom_in_on_the_barcode: 'Aproxime o código de barras',
    refresh: 'Atualizar',
    network_error: 'Erro de rede',
    terms_and_conditions: 'Termos e Condições',
    terms_and_conditions_0: 'Última atualização: 1 de Setembro de 2024',
    terms_and_conditions_p0: 'Bem-vindo(a) à nossa aplicação web ("Aplicação"). Estes Termos e Condições ("Termos") regem o uso da Aplicação. Ao utilizar a Aplicação, você concorda com os presentes Termos. Leia-os cuidadosamente antes de continuar.',
    terms_and_conditions_1: '1. Finalidade da Aplicação',
    terms_and_conditions_p1: 'Nossa Aplicação permite aos usuários acessar determinadas funcionalidades que utilizam a câmera e a geolocalização do dispositivo, de acordo com os objetivos definidos pela própria Aplicação. O uso dessas funcionalidades é essencial para a experiência oferecida, porém, os dados gerados por essas funcionalidades não são transmitidos ou armazenados em servidores externos.',
    terms_and_conditions_2: '2. Privacidade e Segurança dos Dados',
    terms_and_conditions_21: '2.1 Uso da Câmera',
    terms_and_conditions_p21: 'Ao usar a Aplicação, você poderá permitir o acesso à câmera do seu dispositivo. As imagens ou vídeos capturados através da câmera são processados localmente no seu dispositivo. Não coletamos, transmitimos ou armazenamos nenhuma imagem, vídeo ou qualquer dado gerado pela câmera.',
    terms_and_conditions_22: '2.2 Uso da Geolocalização',
    terms_and_conditions_p22: 'Com o seu consentimento, a Aplicação pode acessar a sua localização geográfica. Esta informação é usada exclusivamente para fornecer serviços ou funcionalidades específicas da Aplicação. Assim como os dados da câmera, as informações de geolocalização não são armazenadas ou transmitidas para servidores externos.',
    terms_and_conditions_3: '3. Consentimento do Usuário',
    terms_and_conditions_p3: 'Antes de utilizar a câmera e a geolocalização do dispositivo, será solicitado o seu consentimento explícito. O acesso a essas funcionalidades pode ser revogado a qualquer momento nas configurações do seu dispositivo.',
    terms_and_conditions_4: '4. Responsabilidade do Usuário',
    terms_and_conditions_p4: 'Você é responsável por manter o seu dispositivo seguro e garantir que as permissões de uso da câmera e da geolocalização estejam devidamente configuradas de acordo com a sua preferência.',
    terms_and_conditions_5: '5. Limitação de Responsabilidade',
    terms_and_conditions_p5: 'Na medida permitida pela lei aplicável, a Aplicação e seus desenvolvedores não se responsabilizam por quaisquer danos, diretos ou indiretos, que possam resultar do uso da Aplicação, incluindo, mas não limitado a, falhas no dispositivo, perdas de dados ou violação da privacidade decorrente de configurações incorretas ou uso indevido do dispositivo.',
    terms_and_conditions_6: '6. Alterações nos Termos',
    terms_and_conditions_p6: 'Podemos revisar estes Termos periodicamente para refletir mudanças nas funcionalidades ou na legislação. Recomendamos que você consulte esta página regularmente para estar ciente de eventuais atualizações.',
    terms_and_conditions_7: '7. Contato',
    terms_and_conditions_p7: 'Caso tenha dúvidas ou preocupações sobre o uso da câmera, geolocalização ou outros aspectos relacionados à privacidade, entre em contato conosco através do e-mail: ',
  },
  it: {
    welcome: 'Benvenuti a PãoDuro',
    description: 'Confronta online i prezzi dei supermercati.',
    compare_prices_now: 'Inizia ora!',
    barcode: 'Codice a barre:',
    product_added: 'Prodotto aggiunto al carrello!',
    information: 'Informazioni',
    info_p1: 'Le grandi catene di distribuzione alimentare utilizzano comunemente le carte fedeltà per raccogliere informazioni sulle vendite. Questi dati vengono poi analizzati per ottenere informazioni sul comportamento dei clienti, gestire i prezzi, controllare l\'inventario e ridurre al minimo i costi operativi.',
    info_p2: 'Nonostante il valore relativamente basso dei singoli articoli, la somma mensile collettiva spesa per la spesa può avere un impatto significativo sul budget di una famiglia.',
    info_p3: 'Proprio come confrontiamo i prezzi tra diversi negozi quando facciamo acquisti di valore elevato, è utile fare lo stesso anche per gli articoli di valore basso. Tuttavia, questo può essere difficile perché i prezzi fluttuano frequentemente.',
    info_p4: 'Utilizzare uno strumento che mostra i prezzi più bassi è utile, ma è anche importante implementare ulteriori strategie di risparmio come:',
    info_p4_1: 'Impostare un budget.',
    info_p4_2: 'Creare una lista della spesa.',
    info_p4_3: 'Sfruttare promozioni e coupon.',
    info_p4_4: 'Confrontare i prezzi per unità.',
    info_p4_5: 'Evitare piccoli acquisti frequenti.',
    info_p4_6: 'Anche partecipare a gruppi di acquisto collettivi può essere utile.',
    info_p5: 'Adottare queste pratiche ti consente di gestire il tuo budget e massimizzare i tuoi risparmi sulla spesa.',
    info_p6: '',
    info_p7: 'I prezzi utilizzati nel confronto possono differire da quelli nei negozi fisici.',
    info_p8: 'È sempre consigliabile usare le informazioni di questo strumento in modo critico.',
    info_p9: 'Ci piacerebbe sentire la tua opinione! Per feedback, suggerimenti o domande, contattaci a',
    info_p10: 'Il tuo contributo ci aiuta a creare un\'esperienza migliore.',
    info_p11: 'Grazie e buon shopping!',
    search_title: 'Ricerca',
    save_title: 'Salva',
    settings: 'Impostazioni',
    settings_find_stores: 'Trova i negozi più vicini',
    settings_note: 'Nota: la tua privacy è protetta e la tua posizione non verrà abbandonata dal tuo dispositivo.',
    loading: 'Caricamento',
    store: 'Negozio',
    stores: 'Negozi',
    stores_not_found: 'Nessun negozio trovato!',
    location_required: 'Richiede l\'utilizzo della tua posizione attuale',
    waiting: 'Attendi ...',
    no_matches_found: 'Nessuna corrispondenza trovata',
    review_configs: 'Si prega di rivedere le configurazioni aquí',
    start_scanner: 'avviare lo scanner',
    stop_scanner: 'fermare lo scanner',
    shopping_list: 'Lista della spesa',
    error_cam: 'Si prega di consentire l\'accesso alla fotocamera per avviare la scansione dei codici a barre. La tua privacy è la nostra priorità; la fotocamera verrà utilizzata solo per la scansione. Nessuna immagine verrà trasmessa dal tuo dispositivo.',
    total: 'Totale',
    total_per_store: 'Totale per negozio',
    zoom_in_on_the_barcode: 'Ingrandire il codice a barre',
    refresh: 'Aggiornamento',
    network_error: 'Errore di rete',
    terms_and_conditions: 'Termini e Condizioni',
    terms_and_conditions_0: 'Ultimo aggiornamento: 1 settembre 2024',
    terms_and_conditions_p0: 'Benvenuto/a nella nostra applicazione web ("PãoDuro"). Questi Termini e Condizioni ("Termini") regolano l\'uso dell\'Applicazione. Utilizzando l\'Applicazione, accetti questi Termini. Ti preghiamo di leggerli attentamente prima di procedere.',
    terms_and_conditions_1: '1. Scopo dell\'Applicazione',
    terms_and_conditions_p1: 'La nostra Applicazione consente agli utenti di accedere a determinate funzionalità che utilizzano la fotocamera e i servizi di geolocalizzazione del dispositivo, come richiesto per le funzionalità fornite. L\'utilizzo di queste funzionalità è essenziale per l\'esperienza dell\'utente, ma tutti i dati generati attraverso queste funzionalità vengono elaborati localmente sul dispositivo dell\'utente e non vengono trasmessi o memorizzati su server esterni.',
    terms_and_conditions_2: '2. Privacy e Sicurezza dei Dati',
    terms_and_conditions_21: '2.1 Uso della Fotocamera',
    terms_and_conditions_p21: 'Quando utilizzi l\'Applicazione, puoi consentire l\'accesso alla fotocamera del tuo dispositivo. Tutte le immagini o i video catturati tramite la fotocamera vengono elaborati localmente sul tuo dispositivo. Non raccogliamo, trasmettiamo o memorizziamo immagini, video o altri dati generati dalla fotocamera.',
    terms_and_conditions_22: '2.2 Uso della Geolocalizzazione',
    terms_and_conditions_p22: 'Con il tuo consenso, l\'Applicazione può accedere alla tua posizione geografica. Queste informazioni vengono utilizzate esclusivamente per fornire determinati servizi o funzionalità dell\'Applicazione. Come per i dati della fotocamera, le informazioni sulla posizione non vengono memorizzate né trasmesse a server esterni.',
    terms_and_conditions_3: '3. Consenso dell\'Utente',
    terms_and_conditions_p3: 'Prima di utilizzare le funzionalità della fotocamera e della geolocalizzazione del dispositivo, ti verrà richiesto il consenso esplicito. L\'accesso a queste funzionalità può essere revocato in qualsiasi momento attraverso le impostazioni del dispositivo.',
    terms_and_conditions_4: '4. Responsabilità dell\'Utente',
    terms_and_conditions_p4: 'Sei responsabile del mantenimento della sicurezza del tuo dispositivo e dell\'assicurarti che le autorizzazioni della fotocamera e della geolocalizzazione siano configurate secondo le tue preferenze.',
    terms_and_conditions_5: '5. Limitazione di Responsabilità',
    terms_and_conditions_p5: 'Nella misura consentita dalla legge applicabile, l\'Applicazione e i suoi sviluppatori non sono responsabili per eventuali danni diretti o indiretti che possano derivare dall\'uso dell\'Applicazione, inclusi, ma non limitati a, malfunzionamenti del dispositivo, perdita di dati o violazioni della privacy derivanti da configurazioni errate o uso improprio del dispositivo.',
    terms_and_conditions_6: '6. Modifiche ai Termini',
    terms_and_conditions_p6: 'Possiamo rivedere periodicamente questi Termini per riflettere i cambiamenti nelle funzionalità o nella legge applicabile. Ti consigliamo di rivedere regolarmente questa pagina per rimanere informato/a su eventuali aggiornamenti.',
    terms_and_conditions_7: '7. Contatti',
    terms_and_conditions_p7: 'Se hai domande o preoccupazioni riguardo l\'uso della fotocamera, della geolocalizzazione o altre questioni relative alla privacy, contattaci a: ',
  },
  de: {
    welcome: "Willkommen bei PãoDuro",
    description: 'Vergleichen Sie die Supermarktpreise online.',
    compare_prices_now: 'Jetzt starten!',
    barcode: 'Barcode:',
    product_added: 'Produkt in den Warenkorb gelegt!',
    information: 'Information',
    info_p1: 'Große Lebensmittelvertriebsketten verwenden häufig Kundenkarten, um Verkaufsinformationen zu sammeln. Diese Daten werden dann analysiert, um Einblicke in das Kundenverhalten zu erhalten, Preise zu verwalten, Lagerbestände zu kontrollieren und Betriebskosten zu minimieren.',
    info_p2: 'Trotz des relativ geringen Werts einzelner Artikel kann die monatliche Gesamtsumme, die für Lebensmittel ausgegeben wird, das Budget einer Familie erheblich beeinflussen.',
    info_p3: 'So wie wir bei Einkäufen mit hohem Wert die Preise verschiedener Geschäfte vergleichen, ist es auch von Vorteil, dies bei Artikeln mit geringem Wert zu tun. Dies kann jedoch eine Herausforderung sein, da die Preise häufig schwanken.',
    info_p4: 'Die Verwendung eines Tools, das die niedrigsten Preise anzeigt, ist hilfreich, aber es ist auch wichtig, zusätzliche Strategien zum Geldsparen umzusetzen, wie z. B.:',
    info_p4_1: 'Ein Budget festlegen.',
    info_p4_2: 'Eine Einkaufsliste erstellen.',
    info_p4_3: 'Aktionen und Coupons nutzen.',
    info_p4_4: 'Preise pro Einheit vergleichen.',
    info_p4_5: 'Häufige Kleinkäufe vermeiden.',
    info_p4_6: 'Auch die Teilnahme an gemeinsamen Einkaufsgruppen kann von Vorteil sein.',
    info_p5: 'Durch die Anwendung dieser Praktiken können Sie Ihr Budget verwalten und Ihre Einsparungen beim Lebensmitteleinkauf maximieren.',
    info_p6: '',
    info_p7: 'Die im Vergleich verwendeten Preise können von denen in physischen Geschäften abweichen.',
    info_p8: 'Es ist immer ratsam, die Informationen aus diesem Tool kritisch zu nutzen.',
    info_p9: 'Wir freuen uns, von Ihnen zu hören! Für Feedback, Vorschläge oder Fragen kontaktieren Sie uns unter',
    info_p10: 'Ihre Eingabe hilft uns, ein besseres Erlebnis zu schaffen.',
    info_p11: 'Vielen Dank und viel Spaß beim Einkaufen!',
    search_title: 'Suchen',
    save_title: 'Speichern',
    settings: 'Einstellungen',
    settings_find_stores: 'Die nächstgelegenen Geschäfte finden',
    settings_note: 'Hinweis: Ihre Privatsphäre ist geschützt und Ihr Standort wird Ihr Gerät nicht verlassen.',
    loading: 'Wird geladen',
    store: 'Geschäft',
    stores: 'Geschäfte',
    stores_not_found: 'Kein Store gefunden!',
    location_required: 'Requires the usage of your current location.',
    waiting: 'Please wait ...',
    no_matches_found: 'Keine Übereinstimmungen gefunden',
    review_configs: 'Bitte überprüfen Sie die Konfigurationen hier',
    start_scanner: 'scanner starten',
    stop_scanner: 'scanner stoppen',
    shopping_list: 'Einkaufsliste',
    error_cam: 'Bitte erlauben Sie den Kamerazugriff, um mit dem Scannen von Barcodes zu beginnen. Ihre Privatsphäre hat für uns Priorität; die Kamera wird nur zum Scannen verwendet. Von Ihrem Gerät werden keine Bilder übertragen.',
    total: 'Gesamt',
    total_per_store: 'Gesamt pro Geschäft',
    zoom_in_on_the_barcode: 'Vergrößern Sie den Barcode',
    refresh: 'Aktualisieren',
    network_error: 'Netzwerkfehler',
    terms_and_conditions: 'Allgemeine Geschäftsbedingungen',
    terms_and_conditions_0: 'Zuletzt aktualisiert: 1. September 2024',
    terms_and_conditions_p0: 'Willkommen bei unserer Webanwendung ("PãoDuro"). Diese Allgemeinen Geschäftsbedingungen ("Bedingungen") regeln Ihre Nutzung der Anwendung. Durch die Nutzung der Anwendung stimmen Sie diesen Bedingungen zu. Bitte lesen Sie diese sorgfältig, bevor Sie fortfahren.',
    terms_and_conditions_1: '1. Zweck der Anwendung',
    terms_and_conditions_p1: 'Unsere Anwendung ermöglicht es den Nutzern, auf bestimmte Funktionen zuzugreifen, die die Kamera und die Geolokalisierungsdienste des Geräts verwenden, soweit dies für die bereitgestellten Funktionen erforderlich ist. Die Nutzung dieser Funktionen ist für die Benutzererfahrung unerlässlich, aber alle durch diese Funktionen generierten Daten werden lokal auf dem Gerät des Benutzers verarbeitet und nicht auf externen Servern übertragen oder gespeichert.',
    terms_and_conditions_2: '2. Datenschutz und Datensicherheit',
    terms_and_conditions_21: '2.1 Nutzung der Kamera',
    terms_and_conditions_p21: 'Bei der Nutzung der Anwendung können Sie den Zugriff auf die Kamera Ihres Geräts zulassen. Alle über die Kamera aufgenommenen Bilder oder Videos werden lokal auf Ihrem Gerät verarbeitet. Wir sammeln, übertragen oder speichern keine Bilder, Videos oder andere von der Kamera generierte Daten.',
    terms_and_conditions_22: '2.2 Nutzung der Geolokalisierung',
    terms_and_conditions_p22: 'Mit Ihrem Einverständnis kann die Anwendung auf Ihre geografische Position zugreifen. Diese Informationen werden ausschließlich dazu verwendet, bestimmte Dienste oder Funktionen der Anwendung bereitzustellen. Wie die Kameradaten werden auch die Standortinformationen weder gespeichert noch an externe Server übertragen.',
    terms_and_conditions_3: '3. Zustimmung des Nutzers',
    terms_and_conditions_p3: 'Bevor Sie die Kamera- und Geolokalisierungsfunktionen des Geräts verwenden, wird Ihre ausdrückliche Zustimmung angefordert. Der Zugriff auf diese Funktionen kann jederzeit über die Geräteeinstellungen widerrufen werden.',
    terms_and_conditions_4: '4. Verantwortung des Nutzers',
    terms_and_conditions_p4: 'Sie sind dafür verantwortlich, die Sicherheit Ihres Geräts zu gewährleisten und sicherzustellen, dass die Berechtigungen für Kamera und Geolokalisierung gemäß Ihren Vorlieben konfiguriert sind.',
    terms_and_conditions_5: '5. Haftungsbeschränkung',
    terms_and_conditions_p5: 'Soweit dies nach geltendem Recht zulässig ist, haften die Anwendung und ihre Entwickler nicht für direkte oder indirekte Schäden, die durch die Nutzung der Anwendung entstehen können, einschließlich, aber nicht beschränkt auf Gerätemängel, Datenverlust oder Datenschutzverletzungen, die durch falsche Gerätekonfigurationen oder Missbrauch entstehen.',
    terms_and_conditions_6: '6. Änderungen der Bedingungen',
    terms_and_conditions_p6: 'Wir können diese Bedingungen regelmäßig überprüfen, um Änderungen der Funktionen oder des geltenden Rechts zu berücksichtigen. Wir empfehlen Ihnen, diese Seite regelmäßig zu überprüfen, um über eventuelle Aktualisierungen informiert zu bleiben.',
    terms_and_conditions_7: '7. Kontakt',
    terms_and_conditions_p7: 'Wenn Sie Fragen oder Bedenken hinsichtlich der Nutzung der Kamera, der Geolokalisierung oder anderer datenschutzrelevanter Angelegenheiten haben, kontaktieren Sie uns bitte unter: ',
  },
  cs: {
    welcome: "Vítejte v destinaci PãoDuro",
    description: 'Nástroj pro online srovnání cen!',
    compare_prices_now: 'Začněte nyní!',
    barcode: 'čárový kód:',
    product_added: 'Produkt bylo přidáno do košíku!',
    information: 'Informace',
    info_p1: 'Velké distribuční řetězce potravin běžně používají věrnostní karty ke shromažďování informací o prodeji. Tato data jsou poté analyzována, aby bylo možné získat přehled o chování zákazníků, řídit ceny, kontrolovat zásoby a minimalizovat provozní náklady.',
    info_p2: 'I přes relativně nízkou hodnotu jednotlivých položek může souhrnná měsíční částka utracená za potraviny výrazně ovlivnit rodinný rozpočet.',
    info_p3: 'Stejně jako při nákupech s vysokou hodnotou porovnáváme ceny v různých obchodech, je také výhodné udělat totéž pro položky s nízkou hodnotou. To však může být náročné, protože ceny často kolísají.',
    info_p4: 'Použití nástroje, který zobrazuje nejnižší ceny, je užitečné, ale je také důležité implementovat další strategie pro úsporu peněz, jako jsou:',
    info_p4_1: 'Stanovení rozpočtu.',
    info_p4_2: 'Vytvoření nákupního seznamu.',
    info_p4_3: 'Využití akcí a kuponů.',
    info_p4_4: 'Porovnání cen za jednotku.',
    info_p4_5: 'Vyhněte se častým malým nákupům.',
    info_p4_6: 'Prospěšná může být i účast ve skupinách kolektivního nákupu.',
    info_p5: 'Přijetí těchto postupů vám umožní spravovat svůj rozpočet a maximalizovat úspory v potravinách.',
    info_p6: '',
    info_p7: 'Ceny použité v porovnání se mohou lišit od cen v kamenných obchodech.',
    info_p8: 'Vždy je vhodné používat informace z tohoto nástroje kriticky.',
    info_p9: 'Rádi bychom od vás slyšeli! Pro zpětnou vazbu, návrhy nebo dotazy nás kontaktujte na',
    info_p10: 'Váš příspěvek nám pomáhá vytvářet lepší prostředí.',
    info_p11: 'Děkuji a přeji příjemné nakupování!',
    search_title: 'Vyhledávání',
    save_title: 'Uložit',
    settings: 'Nastavení',
    settings_find_stores: 'Hledání nejbližších obchodů',
    settings_note: 'Poznámka: Vaše soukromí je chráněno a vaše poloha neopustí vaše zařízení.',
    loading: 'načítání',
    store: 'Obchod',
    stores: 'Obchody',
    stores_not_found: 'Nebyl nalezen žádný obchod!',
    location_required: 'Vyžaduje použití vaší aktuální polohy',
    waiting: 'Čekejte prosím ...',
    no_matches_found: 'Nebyly nalezeny žádné shody',
    review_configs: 'Prosím zkontrolujte konfigurace zde',
    start_scanner: 'spusťte skener',
    stop_scanner: 'zastavit skener',
    shopping_list: 'Nákupní seznam',
    error_cam: 'Chcete-li zahájit skenování čárových kódů, povolte přístup k fotoaparátu. Vaše soukromí je naší prioritou; fotoaparát bude použit pouze pro skenování. Z vašeho zařízení nebudou přenášeny žádné obrázky.',
    total: 'Celkový',
    total_per_store: 'Celkem za obchod',
    zoom_in_on_the_barcode: 'Přibližte čárový kód',
    refresh: 'Aktualizovat',
    network_error: 'Chyba sítě',
    terms_and_conditions: 'Podmínky používání',
    terms_and_conditions_0: 'Poslední aktualizace: 1. září 2024',
    terms_and_conditions_p0: 'Vítejte v naší webové aplikaci („PãoDuro“). Tyto Podmínky používání („Podmínky“) upravují vaše používání aplikace. Používáním aplikace souhlasíte s těmito podmínkami. Před pokračováním si je prosím pečlivě přečtěte.',
    terms_and_conditions_1: '1. Účel aplikace',
    terms_and_conditions_p1: 'Naše aplikace umožňuje uživatelům přístup k určitým funkcím, které používají kameru a služby geolokace zařízení, pokud jsou nezbytné pro poskytované funkce. Použití těchto funkcí je zásadní pro uživatelský zážitek, ale všechna data generovaná těmito funkcemi jsou zpracovávána lokálně na zařízení uživatele a nejsou přenášena ani ukládána na externích serverech.',
    terms_and_conditions_2: '2. Ochrana soukromí a bezpečnost dat',
    terms_and_conditions_21: '2.1 Používání kamery',
    terms_and_conditions_p21: 'Při používání aplikace můžete povolit přístup ke kameře vašeho zařízení. Všechny obrázky nebo videa pořízené pomocí kamery jsou zpracovávány lokálně na vašem zařízení. Nesbíráme, nepřenášíme ani neukládáme žádné obrázky, videa ani jiná data generovaná kamerou.',
    terms_and_conditions_22: '2.2 Používání geolokace',
    terms_and_conditions_p22: 'S vaším souhlasem může aplikace přistupovat k vaší geografické poloze. Tyto informace jsou používány výhradně k poskytování určitých služeb nebo funkcí aplikace. Stejně jako data z kamery nejsou informace o poloze ukládány ani přenášeny na externí servery.',
    terms_and_conditions_3: '3. Souhlas uživatele',
    terms_and_conditions_p3: 'Před použitím funkcí kamery a geolokace zařízení bude vyžádán váš výslovný souhlas. Přístup k těmto funkcím může být kdykoliv zrušen prostřednictvím nastavení vašeho zařízení.',
    terms_and_conditions_4: '4. Odpovědnost uživatele',
    terms_and_conditions_p4: 'Jste odpovědní za zajištění bezpečnosti vašeho zařízení a za to, že povolení pro kameru a geolokaci jsou nastavena podle vašich preferencí.',
    terms_and_conditions_5: '5. Omezení odpovědnosti',
    terms_and_conditions_p5: 'V rozsahu povoleném platnými zákony nenesou aplikace ani její vývojáři odpovědnost za jakékoli přímé nebo nepřímé škody, které mohou vzniknout v důsledku používání aplikace, včetně, ale nikoli výlučně, poruch zařízení, ztráty dat nebo narušení soukromí v důsledku nesprávných nastavení zařízení nebo nesprávného použití.',
    terms_and_conditions_6: '6. Změny podmínek',
    terms_and_conditions_p6: 'Tyto podmínky můžeme pravidelně revidovat, abychom odráželi změny ve funkcionalitě nebo v platných právních předpisech. Doporučujeme pravidelně kontrolovat tuto stránku, abyste byli informováni o případných aktualizacích.',
    terms_and_conditions_7: '7. Kontakt',
    terms_and_conditions_p7: 'Pokud máte jakékoli dotazy nebo obavy týkající se používání kamery, geolokace nebo jiných otázek týkajících se soukromí, kontaktujte nás na: ',
  },
  sv: {
    welcome: "Välkommen till PãoDuro",
    description: 'Ett verktyg för att jämföra priser online!',
    compare_prices_now: 'Börja nu!',
    barcode: 'Streckkod:',
    product_added: 'Produkten har lagts till i kundvagnen!',
    information: 'Information',
    info_p1: 'Stora livsmedelsdistributionskedjor använder vanligtvis lojalitetskort för att samla in försäljningsinformation. Dessa data analyseras sedan för att få insikter i kundbeteende, hantera priser, kontrollera lager och minimera driftskostnaderna.',
    info_p2: 'Trots det relativt låga värdet på enskilda varor kan den samlade månatliga summan som spenderas på matvaror avsevärt påverka en familjs budget.',
    info_p3: 'Precis som vi jämför priser mellan olika butiker när vi gör inköp av högt värde, är det också fördelaktigt att göra detsamma för varor med lågt värde. Detta kan dock vara utmanande eftersom priserna fluktuerar ofta.',
    info_p4: 'Det är användbart att använda ett verktyg som visar de lägsta priserna, men det är också viktigt att implementera ytterligare strategier för att spara pengar som:',
    info_p4_1: 'Att sätta en budget.',
    info_p4_2: 'Skapa en inköpslista.',
    info_p4_3: 'Dra nytta av kampanjer och kuponger.',
    info_p4_4: 'Jämför priser per enhet.',
    info_p4_5: 'Undvik täta småköp.',
    info_p4_6: 'Att delta i kollektiva inköpsgrupper kan också vara fördelaktigt.',
    info_p5: 'Genom att använda dessa metoder kan du hantera din budget och maximera dina matbesparingar.',
    info_p6: '',
    info_p7: 'Priserna som används i jämförelsen kan skilja sig från de i fysiska butiker.',
    info_p8: 'Det är alltid tillrådligt att använda informationen från detta verktyg kritiskt.',
    info_p9: 'Vi vill gärna höra från dig! För feedback, förslag eller frågor, kontakta oss på',
    info_p10: 'Din input hjälper oss att skapa en bättre upplevelse.',
    info_p11: 'Tack och trevlig shopping!',
    search_title: 'Sök',
    save_title: 'Spara',
    settings: 'inställningar',
    settings_find_stores: 'Hitta de närmaste butikerna',
    settings_note: 'Obs! Din integritet är skyddad och din plats kommer inte att lämna din enhet.',
    loading: 'Läser in',
    store: 'Lagra',
    stores: 'Butiker',
    stores_not_found: 'Ingen butik hittades!',
    location_required: 'Kräver användning av din nuvarande plats',
    waiting: 'Vänligen vänta ...',
    no_matches_found: 'Inga matchningar hittades',
    review_configs: 'Granska gärna konfigurationerna här',
    start_scanner: 'starta skannern',
    stop_scanner: 'stoppa skannern',
    shopping_list: 'Inköpslista',
    error_cam: 'Tillåt kameraåtkomst för att börja skanna streckkoder. Din integritet är vår prioritet; kameran kommer endast att användas för skanning. Inga bilder kommer att överföras från din enhet.',
    total: 'Total',
    total_per_store: 'Totalt per butik',
    zoom_in_on_the_barcode: 'Zooma in på streckkoden',
    refresh: 'Uppdatera',
    network_error: 'Nätverksfel',
    terms_and_conditions: 'Villkor',
    terms_and_conditions_0: 'Senast uppdaterad: 1 september 2024',
    terms_and_conditions_p0: 'Välkommen till vår webbapplikation ("PãoDuro"). Dessa Villkor ("Villkor") styr din användning av applikationen. Genom att använda applikationen accepterar du dessa villkor. Vänligen läs dem noggrant innan du fortsätter.',
    terms_and_conditions_1: '1. Syfte med applikationen',
    terms_and_conditions_p1: 'Vår applikation gör det möjligt för användare att komma åt vissa funktioner som använder enhetens kamera och geolokaliseringstjänster, i den utsträckning det behövs för att tillhandahålla dessa funktioner. Användningen av dessa funktioner är avgörande för användarupplevelsen, men all data som genereras genom dessa funktioner bearbetas lokalt på användarens enhet och överförs eller lagras inte på externa servrar.',
    terms_and_conditions_2: '2. Sekretess och datasäkerhet',
    terms_and_conditions_21: '2.1 Kameranvändning',
    terms_and_conditions_p21: 'När du använder applikationen kan du tillåta åtkomst till din enhets kamera. Alla bilder eller videor som tas med kameran bearbetas lokalt på din enhet. Vi samlar inte in, överför eller lagrar några bilder, videor eller annan data som genereras av kameran.',
    terms_and_conditions_22: '2.2 Användning av geolokalisering',
    terms_and_conditions_p22: 'Med ditt samtycke kan applikationen få tillgång till din geografiska position. Denna information används uteslutande för att tillhandahålla vissa tjänster eller funktioner i applikationen. Liksom kameradata lagras eller överförs inte platsinformation till externa servrar.',
    terms_and_conditions_3: '3. Användarens samtycke',
    terms_and_conditions_p3: 'Innan du använder kamerans och geolokaliseringens funktioner på enheten kommer ditt uttryckliga samtycke att begäras. Åtkomst till dessa funktioner kan när som helst återkallas via enhetens inställningar.',
    terms_and_conditions_4: '4. Användarens ansvar',
    terms_and_conditions_p4: 'Du är ansvarig för att upprätthålla säkerheten för din enhet och säkerställa att kamerans och geolokaliseringens behörigheter är konfigurerade enligt dina preferenser.',
    terms_and_conditions_5: '5. Ansvarsbegränsning',
    terms_and_conditions_p5: 'I den utsträckning som tillämplig lag tillåter ansvarar applikationen och dess utvecklare inte för direkta eller indirekta skador som kan uppstå genom användningen av applikationen, inklusive men inte begränsat till enhetsfel, dataförlust eller integritetsbrott på grund av felaktiga enhetsinställningar eller felaktig användning.',
    terms_and_conditions_6: '6. Ändringar av villkoren',
    terms_and_conditions_p6: 'Vi kan revidera dessa villkor regelbundet för att återspegla ändringar i funktioner eller tillämplig lag. Vi rekommenderar att du regelbundet granskar denna sida för att hålla dig informerad om eventuella uppdateringar.',
    terms_and_conditions_7: '7. Kontakt',
    terms_and_conditions_p7: 'Om du har några frågor eller funderingar angående användningen av kameran, geolokaliseringen eller andra integritetsrelaterade frågor, vänligen kontakta oss på: ',

  },
  uk: {
    welcome: "Ласкаво просимо до PãoDuro",
    description: 'Інструмент для порівняння цін онлайн!',
    compare_prices_now: 'Почніть зараз!',
    barcode: 'Штрих-код:',
    product_added: 'Товар додано до кошика!',
    information: 'Інформація',
    info_p1: 'Великі торговельні мережі зазвичай використовують картки постійного клієнта для збору інформації про продажі. Потім ці дані аналізуються, щоб отримати уявлення про поведінку клієнтів, керувати цінами, контролювати запаси та мінімізувати операційні витрати.',
    info_p2: 'Незважаючи на відносно низьку вартість окремих товарів, загальна місячна сума витрат на продукти може суттєво вплинути на сімейний бюджет.',
    info_p3: 'Подібно до того, як ми порівнюємо ціни в різних магазинах, роблячи дорогі покупки, також корисно робити те саме для дешевих товарів. Однак це може бути складно, оскільки ціни часто коливаються.',
    info_p4: 'Використання інструменту, який відображає найнижчі ціни, є корисним, але також важливо запровадити додаткові стратегії економії грошей, наприклад:',
    info_p4_1: 'Встановлення бюджету.',
    info_p4_2: 'Створення списку покупок.',
    info_p4_3: 'Використання акцій і купонів.',
    info_p4_4: 'Порівняння цін за одиницю.',
    info_p4_5: 'Уникайте частих дрібних покупок.',
    info_p4_6: 'Участь у групах колективних закупівель також може бути корисною.',
    info_p5: 'Застосування цих практик дозволить вам керувати своїм бюджетом і максимізувати свої заощадження на продуктах.',
    info_p6: 'Важливо зазначити, що інструмент щодня використовує три оновлені джерела цін.',
    info_p7: 'Ціни, використані для порівняння, можуть відрізнятися від цін у звичайних магазинах.',
    info_p8: 'Завжди радимо критично використовувати інформацію з цього інструменту.',
    info_p9: 'Ми будемо раді почути від вас! Щоб отримати відгук, пропозиції чи запитання, зв’яжіться з нами за адресою',
    info_p10: 'Ваш внесок допомагає нам створити кращий досвід.',
    info_p11: 'Дякую, вдалих покупок!',
    search_title: 'Пошук',
    save_title: 'зберегти',
    settings: 'Налаштування',
    settings_find_stores: 'Пошук найближчих магазинів',
    settings_note: 'Примітка. Ваша конфіденційність захищена, і ваше місцезнаходження не залишиться на пристрої.',
    loading: 'Завантаження',
    store: 'Магазин',
    stores: 'Магазинах',
    stores_not_found: 'Магазин не знайдено!',
    location_required: 'Потрібне використання вашого поточного місцезнаходження',
    waiting: 'Будь ласка, зачекайте ...',
    no_matches_found: 'Збігів не знайдено',
    review_configs: 'Будь ласка, перегляньте конфігурації тут',
    start_scanner: 'запустити сканер',
    stop_scanner: 'зупинити сканер',
    shopping_list: 'Список покупок',
    error_cam: 'Щоб розпочати сканування штрих-кодів, надайте доступ до камери. Ваша конфіденційність є нашим пріоритетом; камера буде використовуватися лише для сканування. Жодні зображення не передадуться з вашого пристрою.',
    total: 'Всього',
    total_per_store: 'Усього на магазин',
    zoom_in_on_the_barcode: 'Збільште штрих-код',
    refresh: 'оновлення',
    network_error: 'Помилка мережі',
    terms_and_conditions: 'Умови використання',
    terms_and_conditions_0: 'Останнє оновлення: 1 вересня 2024',
    terms_and_conditions_p0: 'Ласкаво просимо до нашого веб-додатку ("PãoDuro"). Ці Умови використання ("Умови") регулюють використання вами додатку. Використовуючи додаток, ви погоджуєтесь із цими Умовами. Будь ласка, уважно прочитайте їх перед тим, як продовжити.',
    terms_and_conditions_1: '1. Мета додатку',
    terms_and_conditions_p1: 'Наш додаток дозволяє користувачам отримати доступ до певних функцій, які використовують камеру та послуги геолокації пристрою, необхідні для надання відповідних функцій. Використання цих функцій є важливим для користувацького досвіду, проте всі дані, які генеруються через ці функції, обробляються локально на пристрої користувача і не передаються чи зберігаються на зовнішніх серверах.',
    terms_and_conditions_2: '2. Конфіденційність та безпека даних',
    terms_and_conditions_21: '2.1 Використання камери',
    terms_and_conditions_p21: 'При використанні додатку ви можете дозволити доступ до камери вашого пристрою. Всі зображення чи відео, зроблені за допомогою камери, обробляються локально на вашому пристрої. Ми не збираємо, не передаємо і не зберігаємо жодних зображень, відео чи інших даних, отриманих за допомогою камери.',
    terms_and_conditions_22: '2.2 Використання геолокації',
    terms_and_conditions_p22: 'За вашою згодою додаток може отримувати доступ до вашого географічного розташування. Ця інформація використовується виключно для надання певних послуг чи функцій додатку. Як і дані з камери, інформація про місцезнаходження не зберігається і не передається на зовнішні сервери.',
    terms_and_conditions_3: '3. Згода користувача',
    terms_and_conditions_p3: 'Перед використанням функцій камери та геолокації вашого пристрою буде запрошена ваша явна згода. Доступ до цих функцій можна відкликати в будь-який час через налаштування вашого пристрою.',
    terms_and_conditions_4: '4. Відповідальність користувача',
    terms_and_conditions_p4: 'Ви несете відповідальність за підтримку безпеки вашого пристрою та за те, щоб дозволи на використання камери та геолокації були налаштовані відповідно до ваших уподобань.',
    terms_and_conditions_5: '5. Обмеження відповідальності',
    terms_and_conditions_p5: 'У межах, дозволених чинним законодавством, додаток та його розробники не несуть відповідальності за будь-які прямі чи непрямі збитки, які можуть виникнути внаслідок використання додатку, включаючи, але не обмежуючись, несправності пристрою, втрату даних або порушення конфіденційності внаслідок неправильних налаштувань пристрою або його неправильного використання.',
    terms_and_conditions_6: '6. Зміни до Умов',
    terms_and_conditions_p6: 'Ми можемо періодично переглядати ці Умови, щоб враховувати зміни у функціональності або застосовному законодавстві. Рекомендуємо регулярно переглядати цю сторінку, щоб бути в курсі будь-яких оновлень.',
    terms_and_conditions_7: '7. Контакти',
    terms_and_conditions_p7: 'Якщо у вас виникли питання чи сумніви щодо використання камери, геолокації або інших питань, пов’язаних із конфіденційністю, будь ласка, зв’яжіться з нами за адресою: ',
  },
  ru: {
    welcome: 'Добро пожаловать в ПаоДуро',
    description: 'Инструмент для онлайн-сравнения цен!',
    compare_prices_now: 'Начните сейчас!',
    barcode: 'Штрих-код:',
    product_added: 'Товар добавлен в корзину!',
    information: 'Информация',
    info_p1: 'Крупные сети распределения продуктов питания обычно используют карты лояльности для получения информации о продажах. Затем эти данные анализируются, чтобы получить представление о поведении клиентов, управлять операционными ценами, контролировать запасы и минимизировать затраты',
    info_p2: 'Несмотря на относительно низкую стоимость отдельных предметов, совокупная ежемесячная сумма, потраченная на продукты, может существенно повлиять на семейный бюджет.',
    info_p3: 'Подобно тому, как мы сравниваем цены в разных магазинах при совершении дорогостоящих покупок, также выгодно покупать одни и те же недорогие товары. Как всегда, это можно оспорить, поскольку цены часто колеблются',
    info_p4: 'Использование инструмента, отображающего самые низкие цены, полезно, но также важно реализовать дополнительные стратегии экономии денег, такие как:',
    info_p4_1: 'Настройка бюджета.',
    info_p4_2: 'Создание списка покупок.',
    info_p4_3: 'Использование рекламных акций и купонов.',
    info_p4_4: 'Сравнение цен за единицу.',
    info_p4_5: 'Избегать частых мелких покупок.',
    info_p4_6: 'Участие в группах коллективных закупок также может быть полезным.',
    info_p5: 'Принятие этих методов позволит вам управлять своим бюджетом и максимизировать экономию на продуктах.',
    info_p6: '',
    info_p7: 'Цены, используемые при сравнении, могут отличаться от цен в обычных магазинах.',
    info_p8: 'Всегда рекомендуется критически использовать информацию из этого инструмента.',
    info_p9: 'Мы будем рады услышать от вас! Для отзывов, предложений или вопросов свяжитесь с нами по адресу',
    info_p10: 'Ваш вклад помогает нам создавать лучший опыт.',
    info_p11: 'Спасибо и приятных покупок!',
    search_title: 'Поиск',
    save_title: 'Сохранить',
    settings: 'Настройки',
    settings_find_stores: 'Поиск ближайших магазинов',
    settings_note: 'Обратите внимание: ваша конфиденциальность защищена, и ваше местоположение не будет покидать ваше устройство.',
    loading: 'Загрузка',
    store: 'Магазин',
    stores: 'Магазины',
    stores_not_found: 'Магазины не найдены!',
    location_required: 'Вам необходимо использовать ваше текущее местоположение.',
    waiting: 'Пожалуйста, подождите...',
    no_matches_found: 'Совпадения не найдены',
    review_configs: 'Здесь можно плавно просмотреть настройки',
    start_scanner: 'запустить сканер',
    stop_scanner: 'остановить сканер',
    shopping_list: 'Список покупок',
    error_cam: 'Пожалуйста, разрешите доступ камере для запуска лодок сканирования. Ваша конфиденциальность является нашим приоритетом; камера будет использоваться только для сканирования. Никакие изображения не будут передаваться с вашего устройства.',
    total: 'Итого',
    total_per_store: 'Всего по магазину',
    zoom_in_on_the_barcode: 'Увеличить масштаб штрих-кода',
    refresh: 'Обновлять',
    network_error: 'Ошибка сети',
    terms_and_conditions: 'Условия использования',
    terms_and_conditions_0: 'Последнее обновление: 1 сентября 2024 года',
    terms_and_conditions_p0: 'Добро пожаловать в наше веб-приложение ("PãoDuro"). Эти Условия использования ("Условия") регулируют ваше использование приложения. Используя приложение, вы соглашаетесь с этими Условиями. Пожалуйста, внимательно прочитайте их перед продолжением.',
    terms_and_conditions_1: '1. Назначение приложения',
    terms_and_conditions_p1: 'Наше приложение позволяет пользователям получить доступ к определённым функциям, которые используют камеру и сервисы геолокации устройства, необходимые для предоставления этих функций. Использование этих функций является важной частью пользовательского опыта, однако все данные, генерируемые этими функциями, обрабатываются локально на устройстве пользователя и не передаются или сохраняются на внешних серверах.',
    terms_and_conditions_2: '2. Конфиденциальность и безопасность данных',
    terms_and_conditions_21: '2.1 Использование камеры',
    terms_and_conditions_p21: 'При использовании приложения вы можете разрешить доступ к камере вашего устройства. Все изображения или видео, снятые с помощью камеры, обрабатываются локально на вашем устройстве. Мы не собираем, не передаём и не сохраняем изображения, видео или другие данные, полученные с камеры.',
    terms_and_conditions_22: '2.2 Использование геолокации',
    terms_and_conditions_p22: 'С вашего согласия приложение может получать доступ к вашему географическому положению. Эта информация используется исключительно для предоставления определённых услуг или функций приложения. Как и данные камеры, информация о местоположении не сохраняется и не передаётся на внешние серверы.',
    terms_and_conditions_3: '3. Согласие пользователя',
    terms_and_conditions_p3: 'Перед использованием функций камеры и геолокации устройства будет запрошено ваше явное согласие. Доступ к этим функциям может быть отозван в любое время через настройки вашего устройства.',
    terms_and_conditions_4: '4. Ответственность пользователя',
    terms_and_conditions_p4: 'Вы несёте ответственность за обеспечение безопасности вашего устройства и за то, чтобы разрешения для камеры и геолокации были настроены в соответствии с вашими предпочтениями.',
    terms_and_conditions_5: '5. Ограничение ответственности',
    terms_and_conditions_p5: 'В пределах, разрешённых действующим законодательством, приложение и его разработчики не несут ответственности за любые прямые или косвенные убытки, которые могут возникнуть в результате использования приложения, включая, но не ограничиваясь, неисправностями устройства, потерей данных или нарушением конфиденциальности, вызванными неправильной настройкой устройства или его неправильным использованием.',
    terms_and_conditions_6: '6. Изменения условий',
    terms_and_conditions_p6: 'Мы можем периодически пересматривать эти Условия, чтобы учитывать изменения в функционале или действующем законодательстве. Рекомендуем регулярно проверять эту страницу, чтобы быть в курсе обновлений.',
    terms_and_conditions_7: '7. Контакты',
    terms_and_conditions_p7: 'Если у вас есть вопросы или опасения по поводу использования камеры, геолокации или других вопросов, связанных с конфиденциальностью, пожалуйста, свяжитесь с нами по адресу: ',
  },
  ca: {
    welcome: 'Benvingut a PãoDuro',
    description: 'Una eina per comparar preus en línia!',
    compare_prices_now: 'Comença ara!',
    barcode: 'Codi de barres:',
    product_added: 'Producte afegit al carret!',
    information: 'Informació',
    info_p1: 'Les grans cadenes de distribució d\'aliments utilitzen sovint targetes de fidelitat per recollir informació sobre les vendes. Aquestes dades es processen després per obtenir informació sobre el comportament dels clients, gestionar els preus, controlar l\'inventari i minimitzar els costos operatius.',
    info_p2: 'Tot i que el valor dels productes individuals pot ser relativament baix, la suma mensual total gastada en la compra d\'aliments pot tenir un impacte significatiu en el pressupost d\'una família.',
    info_p3: 'De la mateixa manera que comparem preus entre botigues per a compres de valor alt, és beneficiós fer el mateix amb productes de menor valor. No obstant això, això pot ser complicat, ja que els preus fluctuen amb freqüència.',
    info_p4: 'Utilitzar una eina que mostri els preus més baixos és útil, però també és important implementar altres estratègies per estalviar, com ara:',
    info_p4_1: 'Establir un pressupost.',
    info_p4_2: 'Crear una llista de la compra.',
    info_p4_3: 'Aprofitar les promocions i cupons.',
    info_p4_4: 'Comparar preus per unitat.',
    info_p4_5: 'Evitar compres freqüents de petits articles.',
    info_p4_6: 'Participar en grups de compra col·lectiva també pot ser beneficiós.',
    info_p5: 'Adoptar aquestes pràctiques et permetrà gestionar millor el teu pressupost i maximitzar els teus estalvis en la compra d\'aliments.',
    info_p6: '',
    info_p7: 'Els preus utilitzats en la comparació poden variar respecte als preus de les botigues físiques.',
    info_p8: 'Sempre és recomanable utilitzar la informació d\'aquesta eina de manera crítica.',
    info_p9: 'Ens encantaria sentir la teva opinió! Per a comentaris, suggeriments o preguntes, contacta amb nosaltres a',
    info_p10: 'Les teves aportacions ens ajuden a crear una millor experiència.',
    info_p11: 'Gràcies, i feliç compra!',
    search_title: 'Cerca',
    save_title: 'Estalvia',
    settings: 'Configuració',
    settings_find_stores: 'Cerca de botigues més properes',
    settings_note: 'Nota: La teva privadesa està protegida, i la teva ubicació no serà compartida fora del teu dispositiu.',
    loading: 'Carregant',
    store: 'Botiga',
    stores: 'Botigues',
    stores_not_found: 'No s\'ha trobat cap botiga!',
    location_required: 'Cal utilitzar la teva ubicació actual.',
    waiting: 'Si us plau, espera...',
    no_matches_found: 'No s\'han trobat coincidències',
    review_configs: 'Si us plau, revisa la configuració aquí',
    start_scanner: 'inicia l\'escàner',
    stop_scanner: 'atura l\'escàner',
    shopping_list: 'Llista de la compra',
    error_cam: 'Si us plau, permet l\'accés a la càmera per començar a escanejar codis de barres. La teva privadesa és la nostra prioritat; la càmera només s\'utilitzarà per escanejar. No es transmetran imatges des del teu dispositiu.',
    total: 'Total',
    total_per_store: 'Total per botiga',
    zoom_in_on_the_barcode: 'Amplia el codi de barres',
    refresh: 'Actualitza',
    network_error: 'Error de xarxa',
    terms_and_conditions: 'Termes i Condicions',
    terms_and_conditions_0: 'Última actualització: 1 de setembre de 2024',
    terms_and_conditions_p0: 'Benvingut a la nostra aplicació web ("PãoDuro"). Aquests Termes i Condicions ("Termes") regeixen el teu ús de l\'Aplicació. En utilitzar l\'Aplicació, acceptes aquests Termes. Llegeix-los amb atenció abans de continuar.',
    terms_and_conditions_1: '1. Propòsit de l\'aplicació',
    terms_and_conditions_p1: 'La nostra aplicació permet als usuaris accedir a certes funcions que utilitzen la càmera i els serveis de geolocalització del dispositiu, segons sigui necessari per a les funcions proporcionades. L\'ús d\'aquestes funcions és essencial per a l\'experiència de l\'usuari, però totes les dades generades per aquestes funcions es processen localment al dispositiu de l\'usuari i no es transmeten ni s\'emmagatzemen en servidors externs.',
    terms_and_conditions_2: '2. Privacitat i Seguretat de Dades',
    terms_and_conditions_21: '2.1 Ús de la Càmera',
    terms_and_conditions_p21: 'Quan utilitzes l\'aplicació, pots permetre l\'accés a la càmera del teu dispositiu. Totes les imatges o vídeos capturats amb la càmera es processen localment al teu dispositiu. No recopilem, transmetem ni emmagatzemem cap imatge, vídeo o altres dades generades per la càmera.',
    terms_and_conditions_22: '2.2 Ús de la Geolocalització',
    terms_and_conditions_p22: 'Amb el teu consentiment, l\'aplicació pot accedir a la teva ubicació geogràfica. Aquesta informació s\'utilitza exclusivament per proporcionar determinats serveis o funcions de l\'aplicació. Igual que les dades de la càmera, la informació de la ubicació no es guarda ni es transmet a servidors externs.',
    terms_and_conditions_3: '3. Consentiment de l\'Usuari',
    terms_and_conditions_p3: 'Abans d\'utilitzar les funcions de la càmera i la geolocalització del dispositiu, se sol·licitarà el teu consentiment explícit. L\'accés a aquestes funcions es pot revocar en qualsevol moment a través de la configuració del dispositiu.',
    terms_and_conditions_4: '4. Responsabilitat de l\'Usuari',
    terms_and_conditions_p4: 'Tu ets responsable de mantenir la seguretat del teu dispositiu i assegurar-te que els permisos de la càmera i la geolocalització estiguin configurats segons les teves preferències.',
    terms_and_conditions_5: '5. Limitació de Responsabilitat',
    terms_and_conditions_p5: 'En la mesura que ho permeti la llei aplicable, l\'aplicació i els seus desenvolupadors no seran responsables de cap dany directe o indirecte que pugui resultar de l\'ús de l\'aplicació, incloent-hi, però no limitat a, fallades del dispositiu, pèrdua de dades o violacions de la privadesa derivades de configuracions incorrectes del dispositiu o mal ús.',
    terms_and_conditions_6: '6. Canvis als Termes',
    terms_and_conditions_p6: 'Podem revisar aquests Termes periòdicament per reflectir els canvis en les funcionalitats o la legislació aplicable. Et recomanem que revisis aquesta pàgina regularment per estar informat de qualsevol actualització.',
    terms_and_conditions_7: '7. Contacte',
    terms_and_conditions_p7: 'Si tens cap pregunta o inquietud sobre l\'ús de la càmera, la geolocalització o altres qüestions relacionades amb la privadesa, posa\'t en contacte amb nosaltres a: ',
  },
};